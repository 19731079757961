import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import map from 'lodash/map';

import CodeExplanation from '../../containers/CodeExplanation';
import Console from '../../containers/Console';
import GraphicalOutputs from '../../containers/GraphicalOutputs';
import ObjectView from '../../containers/ObjectView';
import Rdoc from '../../containers/RDoc/RDoc';
import Slides from '../../containers/Slides/TabSlides';
import TableHandler from '../../containers/TableHandler';
import {
  GenericEditor,
  ScriptEditor,
  SolutionEditor,
  SolutionReadOnlyEditor,
} from '../CodeEditor/CodeEditorContainer';
import ExerciseAside from '../Exercise/ExerciseAside';

import buildMarkdownLayout from './buildMarkdownLayout';
import buildMCELayout from './buildMCELayout';
import buildNormalLayout from './buildNormalLayout';

/**
 * There is a serie of "build" functions that return a config for golden layout.
 * They are called through the layoutBuilder depending on the type of exercise.
 */

const COMPONENT_MAP = {
  default: {
    script: ScriptEditor,
    solution: SolutionEditor,
    solutionReadOnly: SolutionReadOnlyEditor,
    console: Console,
    slides: Slides,
    codeExplanation: CodeExplanation,
    plot: GraphicalOutputs({
      category: 'graphicalTabs',
      key: 'plot',
    }),
    aside: ExerciseAside,
    html: GraphicalOutputs({
      category: 'graphicalTabs',
      key: 'html',
    }),
    rdoc: Rdoc,
    table: TableHandler,
    objectView: ObjectView,
  },
  sql: {
    script: ScriptEditor,
  },
};

/**
 * Depending on the programming language of the exercise,
 * return the corresponding React component to fill the layout.
 * @param {String} language  exercise language
 * @param {String} key key that maps to some react component
 */
export const getComponent = (language: any) => (key: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  get(COMPONENT_MAP, `${language}.${key}`, COMPONENT_MAP.default[key]) ||
  GenericEditor(key);

export const configToGoldenLayoutItems = (language: any) => (
  configId: any,
  stack: any,
  props: any,
  componentOverride: any,
) => {
  const stackResult = map(stack, (tab, key) => ({
    key,
    id: `${configId}-${key}`,
    type: 'react-component',
    component: `${configId}-${key}`,
    title: tab.title,
    isClosable: get(tab, 'props.isClosable', false),
    parentKey: configId,
    active: tab.props && tab.props.active,
    reactComponent:
      (componentOverride && componentOverride[key]) ||
      getComponent(language)(key),
    props: { ...props, tabCategory: `${configId}`, tabKey: key },
  }));
  const idx = findIndex(stackResult, (t) => t.active);
  return { stack: stackResult, activeItemIndex: idx >= 0 ? idx : undefined };
};

const configToObjectViewLayoutItems = (language: any) => (
  configId: any,
  stack: any,
  props: any,
  componentOverride: any,
) => {
  return map(stack, (tab, key) => ({
    key,
    id: `${configId}-${key}`,
    type: 'react-component',
    component: `${configId}-${key}`,
    title: tab.title,
    isClosable: get(tab, 'props.isClosable', false),
    parentKey: configId,
    active: tab.props && tab.props.active,
    reactComponent:
      (componentOverride && componentOverride[key]) ||
      getComponent(language)('objectView')(key),
    props: { ...props, tabCategory: `${configId}`, tabKey: key },
  }));
};

export const getBottomStack = (language: any) => ({
  onReconnect,
  user: { consoleObjectViewTabs, consoleSqlTabs, consoleTabs },
}: any) => {
  if (language === 'sql') {
    const stack = map(
      { query_result: consoleSqlTabs.query_result, ...consoleSqlTabs },
      (tab, key) => ({
        key,
        id: `sql-${key}`,
        type: 'react-component',
        component: `sql-${key}`,
        title: tab.title,
        isClosable: false,
        parentKey: 'consoleSqlTabs',
        active: tab.props.active,
        reactComponent:
          key === 'codeExplanation'
            ? CodeExplanation
            : getComponent(language)('table')(key),
        props: { onReconnect, tabTitle: tab.title },
        setActiveOnInsert: key === 'query_result',
      }),
    );
    const idx = findIndex(stack, (t) => t.active);
    return { stack, activeItemIndex: idx >= 0 ? idx : undefined };
  }
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  const stack = configToGoldenLayoutItems(language)(
    'consoleTabs',
    consoleTabs,
    {
      onReconnect,
    },
  );
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  const objectViewTabs = configToObjectViewLayoutItems(language)(
    'consoleObjectViewTabs',
    consoleObjectViewTabs,
    {
      onReconnect,
    },
  );

  stack.stack.push(...objectViewTabs);
  return stack;
};

const layoutBuilder = (type: any) => (props: any) => {
  switch (type) {
    case 'MultipleChoiceExercise':
    case 'ConsoleExercise':
    case 'BulletConsoleExercise':
    case 'TabConsoleExercise':
      return buildMCELayout(props);
    case 'MarkdownExercise':
      return buildMarkdownLayout(props);
    default:
      return buildNormalLayout(props);
  }
};
export default layoutBuilder;
