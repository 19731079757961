import { CircleSolid } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import React from 'react';

import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';
import { BACKEND_STATUS, BACKEND_STATUS_CODE } from '../../../redux/selectors';

import useFakeSessionStatusToasts from './useFakeSessionStatusToasts';

const indicatorIconStates = {
  [BACKEND_STATUS.BROKEN.code]: (
    <CircleSolid
      aria-label={BACKEND_STATUS.BROKEN.text}
      css={{ color: tokens.colors.red }}
    />
  ),
  [BACKEND_STATUS.BUSY.code]: (
    <CircleSolid
      aria-label={BACKEND_STATUS.BUSY.text}
      css={{ color: tokens.colors.yellow }}
    />
  ),
  [BACKEND_STATUS.NONE.code]: (
    <CircleSolid
      aria-label="Session Ready"
      css={{ color: tokens.colors.green }}
    />
  ),
  [BACKEND_STATUS.READY.code]: (
    <CircleSolid
      aria-label="Session Initialized"
      css={{ color: tokens.colors.green }}
      data-test-id="session-ready-status-icon"
    />
  ),
  backendInitializing: (
    <CircleSolid
      aria-label="Initializing Session"
      css={{ color: tokens.colors.yellow }}
    />
  ),
};

type BackendSession = {
  status: {
    code: BACKEND_STATUS_CODE;
    text: string;
  };
};

const BackendStatus: React.FC<{ showText?: boolean }> = ({ showText }) => {
  const { fakingSessionIsInitialised } = useFakeSessionStatusToasts();

  const backendSession: BackendSession | undefined = useSelector((state) =>
    selectors.selectBackendSession(state),
  );
  const isInitSession = useSelector(selectors.isSessionInitializing);

  let statusIcon;
  let text = 'Initializing';
  if (fakingSessionIsInitialised) {
    statusIcon = indicatorIconStates[BACKEND_STATUS.NONE.code];
  } else if (isInitSession) {
    statusIcon = indicatorIconStates.backendInitializing;
  } else if (backendSession) {
    statusIcon = indicatorIconStates[backendSession.status.code];
    text =
      backendSession.status.code !== BACKEND_STATUS.READY.code &&
      backendSession.status.text &&
      backendSession.status.text.length > 0
        ? backendSession.status.text
        : 'Connected';
  }
  return (
    <div
      data-cy="header-session"
      css={{
        display: 'flex',
        alignItems: 'center',
        marginRight: tokens.spacing.small,
      }}
    >
      {statusIcon}
      {showText && (
        <Text css={{ marginLeft: tokens.spacing.small }}>{text}</Text>
      )}
    </div>
  );
};

export default BackendStatus;
