import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import ChapterRating from './ChapterRating';

const mapStateToProps = (state: any) => ({
  chapterTitle: selectors.selectChapterTitle(state),
  chapterNumber: selectors.selectChapterNumber(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onRate: (onFinish: any) => ({ feedbackMessage, rating }: any) => {
    dispatch(
      actions.epicSubmitChapterRating({
        extraInfo: feedbackMessage,
        rating,
        onFinish,
      }),
    );
  },
});

const mergeProps = (
  stateProps: any,
  { onRate }: any,
  { onFinish, ...ownProps }: any,
) => ({
  ...stateProps,
  ...ownProps,
  onFinish,
  onRate: onRate(onFinish),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ChapterRating);
