/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkmark from '../Checkmark';
import { pop } from '../keyframes';
import XpBadge from '../XpBadge';

const onShowEventName = 'ds-milestone-display-3';

type Props = {
  onAdvance: (...args: any[]) => any;
  xp?: number;
};

const Step4: React.FC<Props> = ({ onAdvance, xp = 0 }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header>{t('OnboardingMilestonesStep4.header')}</Dialog.Header>
      {xp > 0 && <XpBadge xp={xp} />}
      <Dialog.Body>
        <Checkmark step={3} />
        <Paragraph>{t('OnboardingMilestonesStep4.textOne')}</Paragraph>
        <Paragraph>{t('OnboardingMilestonesStep4.textTwo')}</Paragraph>
      </Dialog.Body>
      <Dialog.Footer data-cy={onShowEventName}>
        <Dialog.Button
          variant="primary"
          onClick={onAdvance}
          iconRight={<ArrowRight />}
          css={{
            animation: `${pop} 0.82s cubic-bezier(.36, .07, .19, .97) both`,
            animationDelay: '1500ms',
          }}
        >
          {t('OnboardingMilestonesStep4.button')}
        </Dialog.Button>
      </Dialog.Footer>
    </>
  );
};

export default Step4;
