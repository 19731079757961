import { constants as pureMultipleChoiceExerciseConstants } from '@datacamp/pure-multiple-choice-exercise-core';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import React, { useEffect } from 'react';

const EXERCISES_WITH_PRELOAD = [
  videoExerciseConstants.exercises.VIDEO_EXERCISE,
  pureMultipleChoiceExerciseConstants.exercises.PURE_MULTIPLE_CHOICE_EXERCISE,
];

function shouldPreloadSessionForExercise(exercise: any) {
  return EXERCISES_WITH_PRELOAD.includes(exercise.type);
}

function MuxSessionPreloader({ exercise, startSession }: any) {
  useEffect(() => {
    if (!shouldPreloadSessionForExercise(exercise)) {
      return;
    }
    startSession(false, {
      type: 'PlainMultipleChoiceExercise',
      pec: '',
    });
  }, [exercise, startSession]);
  return null;
}

function isSameExercise(prevProps: any, nextProps: any) {
  return prevProps.exercise.id === nextProps.exercise.id;
}

export default React.memo(MuxSessionPreloader, isSameExercise);
