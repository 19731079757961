export const defaultSettings = {
  dimensions: {
    borderWidth: 5,
    minItemHeight: 30,
    minItemWidth: 0,
    headerHeight: 32,
  },
  settings: {
    showPopoutIcon: false,
    showMaximiseIcon: false,
    showCloseIcon: false,
    reorderEnabled: false,
  },
};

export const leftPaneWidths = {
  MultipleChoiceExercise: 50,
  ConsoleExercise: 50,
  TabConsoleExercise: 50,
  BulletConsoleExercise: 50,
  MarkdownExercise: 33,
  default: 40,
};
