/** @jsx jsx */
import { jsx } from '@emotion/react';
import find from 'lodash/find';
import * as React from 'react';
import { connect } from 'react-redux';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../assets/audio/campus-upsell-... Remove this comment to see the full error message
import upsellPopupSoundLink from '../../assets/audio/campus-upsell-popup.mp3';
import config from '../../config';
import * as selectors from '../../redux/selectors';

import {
  ISearchLandingPopUpProps,
  SearchLandingPopUp,
} from './SearchLandingPopUp';

export type ISearchLandingPopUpWrapperProps = {
  delay?: number;
  userLoggedIn?: boolean;
} & Omit<ISearchLandingPopUpProps, 'delay'>;

const WHITELIST_REFERRERS_REGEX = [
  new RegExp('^https://www.google.com/.*$'),
  new RegExp('^https://www.statmethods.net/.*$'),
];

const SearchLandingPopUpWrapperInternal: React.FC<ISearchLandingPopUpWrapperProps> = ({
  contentLink,
  delay = 20000,
  popupSoundLink,
  technology,
  title,
  userLoggedIn,
}) => {
  const { referrer } = document;
  const isFromWhitelistReferrer = React.useMemo(
    () =>
      Boolean(find(WHITELIST_REFERRERS_REGEX, (regex) => regex.test(referrer))),
    [referrer],
  );

  if (!isFromWhitelistReferrer || userLoggedIn === undefined || userLoggedIn) {
    return null;
  }

  return (
    <SearchLandingPopUp
      contentLink={contentLink}
      delay={delay}
      popupSoundLink={popupSoundLink}
      technology={technology}
      title={title}
    />
  );
};

export const SearchLandingPopUpWrapper: React.FC<ISearchLandingPopUpWrapperProps> = (
  props,
) =>
  config.isServerSideRendering ? null : (
    <SearchLandingPopUpWrapperInternal {...props} />
  );

const mapStateToProps = (state: any) => ({
  userLoggedIn:
    selectors.selectUser(state).get('status') == null
      ? undefined
      : selectors.selectIsUserLoggedIn(state),
  contentLink: selectors.selectCourse(state).get('link'),
  popupSoundLink: upsellPopupSoundLink,
  technology: selectors.selectTechnology(state),
  title: selectors.selectCourseTitle(state),
});

export default connect(mapStateToProps)(SearchLandingPopUpWrapper);
