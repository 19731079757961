import React, { forwardRef } from 'react';
import { Link as ReactLink } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick?: (...args: any[]) => any;
  rel?: string;
  style?: Record<string, unknown>;
  tabIndex?: number;
  target?: string;
  to?: string;
};

const Link = (
  // eslint-disable-next-line @datacamp/react-use-fc
  { children, onClick, to, ...rest }: Props,
  ref: React.Ref<HTMLAnchorElement>,
): JSX.Element => {
  let onClickHooked = onClick;
  if (!to) {
    onClickHooked = (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    };

    // eslint-disable-next-line no-script-url
    const voidLink = 'javascript:void(0)';
    return (
      <a ref={ref} href={voidLink} onClick={onClickHooked} {...rest}>
        {children}
      </a>
    );
  }

  if (to.startsWith('http') || to.startsWith('//')) {
    return (
      <a ref={ref} href={to} onClick={onClick} {...rest}>
        {children}
      </a>
    );
  }

  // Note: innerRef can be replaced by ref if react-router is updated past v5.1
  // src: https://v5.reactrouter.com/web/api/Link/innerref-refobject
  return (
    <ReactLink innerRef={ref} to={to} onClick={onClickHooked} {...rest}>
      {children}
    </ReactLink>
  );
};

const DCLink = forwardRef(Link);

export default DCLink;
