import React from 'react';

import ProgressIndicator from './ProgressIndicator';

type Props = {
  progress?: any[];
};

export default function ExerciseFooter({ progress }: Props) {
  return (
    <div className="exercise-footer">
      <ProgressIndicator items={progress} />
    </div>
  );
}
