/** @jsx jsx */
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
import { getMCCommand } from '@datacamp/dc-languages-config';
import { HTMLContent } from '@datacamp/le-shared-components';
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { Radio } from '@datacamp/waffles/radio';
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import first from 'lodash/first';
import map from 'lodash/map';
import result from 'lodash/result';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';
import hcKeyListener from '../../HighOrderComponents/hcKeyListener';
import AssignmentText from '../AssignmentText';

import Feedback from './Feedback/Feedback';

const focusInCurrentTarget = (relatedTarget: any, currentTarget: any) => {
  if (relatedTarget === null) {
    return false;
  }

  let node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};

const Choices = forwardRef(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'choices' does not exist on type '{ child... Remove this comment to see the full error message
  ({ choices, language, submitButton }, ref) => (
    <div
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLDivE... Remove this comment to see the full error message
      ref={ref}
    >
      <fieldset
        css={{
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: tokens.spacing.medium,
          border: 0,
          marginBottom: tokens.spacing.medium,
        }}
      >
        {choices}
      </fieldset>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {submitButton}
        {/* @ts-expect-error fix this by fixing type errors of feedback component */}
        <Feedback language={language} />
      </div>
    </div>
  ),
);

Choices.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
  choices: PropTypes.node.isRequired,
  submitButton: PropTypes.element.isRequired,
  language: PropTypes.string.isRequired,
  choicesFocused: PropTypes.bool,
};

const shouldDisableEnterButton = (disabled: any, selectedChoice: any) =>
  disabled || selectedChoice === undefined;

const onEnterToSubmit = (e: any, props: any) => {
  if (e.keyCode === 13) {
    if (!props.choicesFocused) {
      return false;
    }
    if (!props.disabled && !props.isCompleted) {
      props.onClick(e);
    }
    e.preventDefault();
  }
  return false;
};

const SubmitButton = forwardRef(
  (props: any, ref: React.Ref<HTMLButtonElement>) => {
    const { t } = useTranslation();

    return (
      <Tooltip content={t('MultipleChoiceInstructions.enter')} placement="left">
        <Button css={{ alignSelf: 'flex-end' }} {...props} ref={ref} />
      </Tooltip>
    );
  },
);

const KeyboundButton = hcKeyListener(onEnterToSubmit)(SubmitButton);

type Props = {
  disableTitle?: boolean;
  exercise?: {
    id?: number;
    instructions?: string[];
    language: string;
    possible_answers?: string[];
    pre_exercise_code?: string;
    sct?: string;
    type?: string;
    user?: {
      choicesFocused?: boolean;
    };
  };
  exerciseProgress?: {
    last_attempt?: string;
  };
  isCompleted?: boolean;
  onSelectChoice?: (...args: any[]) => any;
  onSubmitChoice?: (...args: any[]) => any;
  optionsContext?: string;
  setMceChoicesFocus: (focus: boolean) => any;
};

const MultipleChoiceInstructions: React.FC<Props> = ({
  isCompleted,
  disableTitle,
  optionsContext,
  onSelectChoice,
  onSubmitChoice,
  setMceChoicesFocus,
  exerciseProgress = {},
  exercise,
}) => {
  const instructionsRef = useRef();
  const { t } = useTranslation();

  const sessionIsBusy = useSelector(selectors.isSessionBusy);
  const sessionIsReady = useSelector(selectors.isSessionReady);

  const onSelectDefaultOption = useCallback(() => {
    const lastChoice = result(exercise, 'user.multipleChoiceAnswer');
    if (lastChoice != null) {
      return;
    }

    const lastAttempt = exerciseProgress.last_attempt || '';
    const option = first(lastAttempt.match(/\d+/g));
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSelectChoice((option && Number(option)) || 1);
  }, [exercise, exerciseProgress, onSelectChoice]);

  const getInstructions = useCallback(() => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'instructions' does not exist on type '{ ... Remove this comment to see the full error message
      instructions,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'possible_answers' does not exist on type... Remove this comment to see the full error message
      possible_answers: possibleAnswers,
    } = exercise;
    // temporary only for backward compatibility
    return instructions || possibleAnswers;
  }, [exercise]);

  useEffect(() => {
    const onGlobalClick = (e: any) => {
      if (instructionsRef.current) {
        setMceChoicesFocus(
          focusInCurrentTarget(e.target, instructionsRef.current),
        );
      }
    };

    document.addEventListener('click', onGlobalClick);
    onSelectDefaultOption();

    return () => {
      document.removeEventListener('click', onGlobalClick);
    };
  }, [onSelectDefaultOption, setMceChoicesFocus]);

  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ id?: numbe... Remove this comment to see the full error message
    id,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'language' does not exist on type '{ id?:... Remove this comment to see the full error message
    language,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'pre_exercise_code' does not exist on typ... Remove this comment to see the full error message
    pre_exercise_code: preExerciseCode,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'sct' does not exist on type '{ id?: numb... Remove this comment to see the full error message
    sct,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'solution' does not exist on type '{ id?:... Remove this comment to see the full error message
    solution,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ id?: num... Remove this comment to see the full error message
    type,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{ id?: num... Remove this comment to see the full error message
    user,
  } = exercise;

  const onSelect = useCallback(
    (event: any) => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onSelectChoice(event.target.value);
    },
    [onSelectChoice],
  );

  const onSubmit = useCallback(() => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSubmitChoice({
      code: getMCCommand(language, user.multipleChoiceAnswer),
      command: 'submit',
      language,
      solution,
      type,
      id,
      pre_exercise_code: preExerciseCode,
      sct,
    });
  }, [
    id,
    language,
    onSubmitChoice,
    preExerciseCode,
    sct,
    solution,
    type,
    user.multipleChoiceAnswer,
  ]);

  // MultipleChoiceInstructions is a container, when switching exercise, the container can be updated before his parent.
  // That can lead to this component receiving a wrong type of exercise, and errorring out because the props does not have the expected shapes.
  if (exercise == null || exercise.type !== 'MultipleChoiceExercise') {
    return null;
  }

  const choices = map(getInstructions(), (choice, i) => (
    <Radio
      key={`${choice}-${i}`}
      onChange={onSelect}
      value={i + 1}
      checked={Number(i + 1) === Number(user.multipleChoiceAnswer)}
      data-cy="mce-option"
    >
      <AssignmentText css={{ marginTop: -2 }}>
        <HTMLContent
          mathJaxEnabled
          html={choice.replace(/^\[([\s\S]+?)\]$/g, '$1')}
        />
      </AssignmentText>
    </Radio>
  ));

  return (
    <div
      css={{
        marginBottom: tokens.spacing.large,
        position: 'relative',
        padding: tokens.spacing.medium,
        backgroundColor: tokens.colors.white,
        overflow: 'hidden',
      }}
    >
      {optionsContext && (
        <div>
          <Heading size="xlarge">
            {t('MultipleChoiceInstructions.optionsContextHeading')}
          </Heading>
          <AssignmentText>
            <HTMLContent html={optionsContext} mathJaxEnabled />
          </AssignmentText>
        </div>
      )}
      {!disableTitle && (
        <Heading size="large">
          {t('MultipleChoiceInstructions.heading')}
        </Heading>
      )}
      <Choices
        ref={instructionsRef}
        {...{
          choices,
          language,
          choicesFocused: user.choicesFocused,
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'submitButton' does not exist on type 'In... Remove this comment to see the full error message
        submitButton={
          <KeyboundButton
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'loading' does not exist on type 'Intrins... Remove this comment to see the full error message
            isLoading={sessionIsBusy}
            disabled={shouldDisableEnterButton(
              !sessionIsReady,
              user.multipleChoiceAnswer,
            )}
            onClick={onSubmit}
            isCompleted={isCompleted}
            choicesFocused={user.choicesFocused}
            language={language}
            selectedChoice={user.multipleChoiceAnswer}
            variant="primary"
            data-test-id="submit-solution-button"
            data-cy="submit-button"
          >
            {t('MultipleChoiceInstructions.primaryButton')}
          </KeyboundButton>
        }
      />
    </div>
  );
};

// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export default hcKeyListener()(MultipleChoiceInstructions);
