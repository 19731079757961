import { TranslationsProvider } from '@datacamp/le-shared-components';
import React from 'react';

import { useSelector } from '../../interfaces/State';
import { selectPathLanguage } from '../../redux/selectors';

export const ConnectedTranslationsProvider: React.FC = ({ children }) => {
  const language = useSelector(selectPathLanguage);

  return (
    <TranslationsProvider language={language}>{children}</TranslationsProvider>
  );
};
