/** @jsx jsx */
import { DataCampLogo } from '@datacamp/waffles/brand';
import { Heading } from '@datacamp/waffles/heading';
import { Link } from '@datacamp/waffles/link';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { Component } from 'react';
import Helmet from 'react-helmet';

import config from '../config';

type OwnProps = {
  description?: string;
  onInit?: () => void;
  pageTitle?: string;
  title?: string;
};

const defaultProps = {
  title: "We're sorry, the page you were looking for doesn't exist.",
  pageTitle: "The page you were looking for doesn't exist (404)",
  description: 'You may have mistyped the address or the page may have moved.',
};

type Props = OwnProps & typeof defaultProps;

class ErrorPage extends Component<Props> {
  static defaultProps = defaultProps;

  componentWillMount() {
    if (this.props.onInit != null) {
      this.props.onInit();
    }
  }

  render() {
    return (
      <div
        css={{
          maxWidth: 900,
          padding: tokens.spacing.medium,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Helmet>
          <title>{this.props.pageTitle}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div
          data-cy="error-card"
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <DataCampLogo height="46" />
          <Heading size="xxlarge" css={{ marginTop: tokens.spacing.medium }}>
            {this.props.title}
          </Heading>
          <Paragraph>{this.props.description}</Paragraph>
          <Link
            css={{
              marginTop: tokens.spacing.medium,
              '&:hover': {
                borderBottomColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
            href={config.urls.datacamp}
          >
            Go back to the homepage
          </Link>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
