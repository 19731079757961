/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from '@datacamp/waffles/icon';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';

import {
  darkThemePanelButton,
  lightThemePanelButton,
  panelButtonPosition,
  sidebarToggleButton,
} from '../../styles/campus/buttonOverrides';

const hcCollapsePanel = ({
  closedBarHeight = 30,
  closedBarWidth = 30,
  defaultSize = 300,
  fromTopToBottom = true,
  paddingBar = 2,
}) => (WrappedComponent: any) => {
  class CollapsePanel extends React.Component {
    initialSize: any;

    constructor(props: any) {
      super(props);

      this.updateContainer({ nextContainer: props.glContainer });
    }

    componentWillReceiveProps(nextProps: any) {
      this.updateContainer({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
        currentContainer: this.props.glContainer,
        nextContainer: nextProps.glContainer,
      });
    }

    componentDidUpdate() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
      const { glContainer, isClosed } = this.props;
      if (glContainer) {
        if (isClosed) {
          this.closePane(true);
        } else if (!isClosed && this.isClosed()) {
          this.closePane(false);
        }
      }
    }

    componentWillUnmount() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
      if (this.props.glContainer) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.glContainer.off('resize', this.onResize);
      }
    }

    /**
     * Set size of the panel in order to collapse or expand it
     * @param {boolean} shouldClose
     */
    closePane(shouldClose: any) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
      if (this.props.glContainer.isHidden) {
        return;
      }
      const width = shouldClose ? closedBarWidth : this.initialSize;
      const height = shouldClose ? closedBarHeight : this.initialSize;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.glContainer.setSize(
        fromTopToBottom ? undefined : width,
        fromTopToBottom ? height : undefined,
      );
    }

    isClosed() {
      if (fromTopToBottom) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
        return this.props.glContainer._element.height() <= closedBarHeight * 2;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
      return this.props.glContainer._element.width() <= closedBarWidth * 3;
    }

    onResize = () => {
      if (!this.isClosed()) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'glContainer' does not exist on type 'Rea... Remove this comment to see the full error message
        this.saveSize(this.props.glContainer);
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isClosed' does not exist on type 'Readon... Remove this comment to see the full error message
      if (this.props.isClosed && this.isClosed()) {
        this.closePane(true);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setClosed' does not exist on type 'Reado... Remove this comment to see the full error message
        this.props.setClosed(this.isClosed());
      }
    };

    saveSize(glContainer: any) {
      if (fromTopToBottom) {
        this.initialSize =
          glContainer._element.height() + paddingBar + closedBarHeight;
      } else {
        this.initialSize = glContainer._element.width();
      }
    }

    updateContainer({ currentContainer, nextContainer }: any = {}) {
      this.initialSize = this.initialSize || defaultSize;
      if (currentContainer !== nextContainer) {
        if (currentContainer) {
          currentContainer.off('resize', this.onResize);
        }

        if (nextContainer) {
          this.saveSize(nextContainer);
          nextContainer.on('resize', this.onResize);
        }
      }
    }

    onToggle = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'setClosed' does not exist on type 'Reado... Remove this comment to see the full error message
      this.props.setClosed(!this.props.isClosed);
    };

    getDataCy = (fromTop: any) => {
      if (fromTop) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isClosed' does not exist on type 'Readon... Remove this comment to see the full error message
        return this.props.isClosed
          ? 'console-panel-maximised'
          : 'console-panel-minimised';
      }

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isClosed' does not exist on type 'Readon... Remove this comment to see the full error message
      return this.props.isClosed
        ? 'exercise-panel-maximised'
        : 'exercise-panel-minimised';
    };

    getCurrentIcon = () => {
      if (fromTopToBottom) {
        // @ts-expect-error props is not typed
        return this.props.isClosed ? <ChevronUp /> : <ChevronDown />;
      }
      // @ts-expect-error props is not typed
      return this.props.isClosed ? <ChevronRight /> : <ChevronLeft />;
    };

    getCurrentButtonCss = () => {
      if (fromTopToBottom) {
        // @ts-expect-error props is not typed
        return this.props.uiTheme === 'LIGHT'
          ? [panelButtonPosition, lightThemePanelButton]
          : [panelButtonPosition, darkThemePanelButton];
      }
      return [
        panelButtonPosition,
        lightThemePanelButton,
        // @ts-expect-error props is not typed
        sidebarToggleButton(this.props.isClosed),
      ];
    };

    render() {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'deactivateToggleButton' does not exist o... Remove this comment to see the full error message */}
          {!this.props.deactivateToggleButton && (
            <Button
              variant="plain"
              aria-label="Toggle panel"
              onClick={this.onToggle}
              css={this.getCurrentButtonCss()}
              data-cy={`${this.getDataCy(fromTopToBottom)}`}
              icon={this.getCurrentIcon()}
              // @ts-expect-error props is not typed
              inverted={fromTopToBottom && this.props.uiTheme !== 'LIGHT'}
            />
          )}
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
  CollapsePanel.propTypes = {
    glContainer: PropTypes.shape({
      on: PropTypes.func.isRequired,
      off: PropTypes.func.isRequired,
      setSize: PropTypes.func.isRequired,
      _element: PropTypes.shape({
        height: PropTypes.func.isRequired,
        width: PropTypes.func.isRequired,
      }),
      isHidden: PropTypes.bool.isRequired,
    }),
    isClosed: PropTypes.bool,
    setClosed: PropTypes.func,
    deactivateToggleButton: PropTypes.bool,
    uiTheme: PropTypes.string,
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
  CollapsePanel.defaultProps = {
    isClosed: false,
    setClosed() {},
    deactivateToggleButton: false,
  };
  return (config: any) => {
    if (config.glContainer) {
      return <CollapsePanel {...config} />;
    }
    return <WrappedComponent {...config} />;
  };
};

export default hcCollapsePanel;
