import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import pickBy from 'lodash/pickBy';

import GraphicalOutputs from '../../containers/GraphicalOutputs';

import buildDefaultLayout from './buildDefaultLayout';
import {
  configToGoldenLayoutItems,
  getBottomStack,
  getComponent,
} from './layoutBuilder';
import { defaultSettings, leftPaneWidths } from './layoutsettings';

export default function buildMCELayout(props: any) {
  const {
    chapter,
    exercise,
    setActiveTab,
    startSession,
    user: { consoleTabs, editorTabs, graphicalTabs },
  } = props;

  if (!chapter.slides_link) {
    delete consoleTabs.slides;
  }

  const filteredEditorTabs = pickBy(
    editorTabs,
    // @ts-expect-error ts-migrate(6133) FIXME: 'item' is declared but its value is never read.
    (item, key) =>
      exercise.language === 'sql' || !includes(['script', 'solution'], key),
  );
  const topLeftStack = configToGoldenLayoutItems(exercise.language)(
    'editorTabs',
    filteredEditorTabs,
    {},
    {
      html: GraphicalOutputs({
        category: 'editorTabs',
        key: 'html',
      }),
    },
  );

  const topRightStack = flatMap(graphicalTabs, (tab, key) => {
    if (tab.props.sources.length <= 0) {
      return [];
    }
    return [
      {
        key,
        id: `graphical-${key}`,
        type: 'react-component',
        component: `graphical-${key}`,
        title: tab.title,
        isClosable: false,
        parentKey: 'graphicalTabs',
        reactComponent: getComponent(exercise.language)(key),
        props: {
          tabCategory: 'graphicalTabs',
          tabKey: key,
        },
      },
    ];
  });

  const bottomStack = getBottomStack(exercise.language)({
    ...props,
    onReconnect: () => startSession(true),
  });

  const sizeSettings =
    exercise.language === 'sql'
      ? {}
      : {
          leftPaneWidth: leftPaneWidths.MultipleChoiceExercise,
          rightPaneWidth: 100 - leftPaneWidths.MultipleChoiceExercise,
        };

  return {
    ...defaultSettings,
    content: [
      buildDefaultLayout({
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; id: string; type: string; com... Remove this comment to see the full error message
        topLeftStack,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; id: string; type: string; com... Remove this comment to see the full error message
        topRightStack,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; id: string; type: string; com... Remove this comment to see the full error message
        bottomStack,
        setActiveTab,
        ...sizeSettings,
      }),
    ],
  };
}
