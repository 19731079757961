/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

type Props = {
  onExit: (...args: any[]) => any;
  videoUrl: string;
};

const VideoDialog: React.FC<Props> = ({ onExit, videoUrl }) => {
  return (
    <Dialog
      isOpen
      onClose={onExit}
      aria-label="Video"
      css={{
        maxWidth: 1200,
        [mediaQuery.aboveSmall]: {
          minWidth: 'min(1200px, 100%)',
        },
      }}
    >
      <div
        css={{
          paddingLeft: tokens.spacing.large,
          paddingRight: tokens.spacing.large,
          paddingTop: tokens.spacing.xlarge,
          paddingBottom: tokens.spacing.large,
        }}
      >
        <div
          css={{
            height: 0,
            overflow: 'hidden',
            paddingTop: '56.25%', // preserves 16:9 ratio
            background: tokens.colors.white,
            position: 'relative',
          }}
        >
          <iframe
            allowFullScreen
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0,
            }}
            src={videoUrl}
            title="Projector video"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default VideoDialog;
