// eslint-disable-next-line filenames/match-exported
import config from '../../config';

type CreateRunExamplesListenerArgs = {
  sendCode: (code: string) => void;
};
const createRunExamplesListener = ({
  sendCode,
}: CreateRunExamplesListenerArgs) => {
  return (event: MessageEvent) => {
    // @ts-expect-error Property 'originalEvent' does not exist on type 'MessageEvent<any>'
    const origin = event.origin || event.originalEvent.origin;
    const payload = event.data;
    if (origin === config.urls.rdoc && typeof payload === 'string') {
      sendCode(payload);
    }
  };
};

export default createRunExamplesListener;
