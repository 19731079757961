/** @jsx jsx */

import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { Fragment } from 'react';

type Props = {
  inverted?: boolean;
  keys: string[];
};

const KeyboardShortcuts: React.FC<Props> = ({ inverted = false, keys }) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacing.xsmall,
      }}
    >
      {keys.map((key, index) => {
        return (
          <Fragment key={key}>
            <Text
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20,
                minWidth: 20,
                color: inverted
                  ? tokens.colors.navySubtleTextOnDark
                  : tokens.colors.navySubtleTextOnLight,
                backgroundColor: inverted
                  ? tokens.colors.navyLight
                  : tokens.colors.greyLight,
                border: `${tokens.borderWidth.thin} solid ${
                  inverted
                    ? tokens.colors.transparentWhite
                    : tokens.colors.transparentGrey
                }`,
                borderRadius: tokens.borderRadius.medium,
                paddingLeft: tokens.spacing.xsmall,
                paddingRight: tokens.spacing.xsmall,
              }}
            >
              {key}
            </Text>
            {index < keys.length - 1 && (
              <Text css={{ color: tokens.colors.navySubtleTextOnLight }}>
                +
              </Text>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default KeyboardShortcuts;
