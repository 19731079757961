/** @jsx jsx */
import { constants as pureMultipleChoiceExerciseConstants } from '@datacamp/pure-multiple-choice-exercise-core';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { Avatar } from '@datacamp/waffles/avatar';
import { Code, Exercise, List, Play, UiCollapse } from '@datacamp/waffles/icon';
import { Loader } from '@datacamp/waffles/loader';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { Map } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';

import { formatUrl } from '../../../helpers/navigation';
import { DEFAULT_LANGUAGE } from '../../../i18n';
import { LearningModeState } from '../../../redux/reducers/learningMode';
import DCLink from '../../DCLink';

import ChapterExerciseXp from './ChapterExerciseXp';

type ExerciseIconProps = {
  iconName: any;
};

const ExerciseIcon: React.FC<ExerciseIconProps> = ({ iconName }) => {
  switch (iconName) {
    case videoExerciseConstants.exercises.VIDEO_EXERCISE:
      return <Play />;
    case pureMultipleChoiceExerciseConstants.exercises
      .PURE_MULTIPLE_CHOICE_EXERCISE:
    case 'ExplorableExercise':
    case 'DragAndDropExercise':
      return <List />;
    case 'ChallengeExercise':
      return <Exercise />;
    case 'RemoteDesktopExercise':
    case 'ChatExercise':
    case 'CloudExercise':
      return <UiCollapse />;
    default:
      return <Code />;
  }
};

const isInternallyCompleted = (ex: any) =>
  ex.user && ex.user.completed && ex.user.completed.completed;
const isCompleted = (progress: any, ex: any) =>
  (progress && progress.completed) || isInternallyCompleted(ex);

const computeXp = (
  progress: any,
  currentXp: any,
  completed: any,
  // @ts-expect-error ts-migrate(6133) FIXME: 'totalXp' is declared but its value is never read.
  totalXp: any,
  ex: any,
) => {
  if (!completed) {
    return 0;
  }
  if (isInternallyCompleted(ex)) {
    if (progress && progress.xp) {
      return progress && progress.xp > currentXp ? progress.xp : currentXp;
    }
    return currentXp;
  }
  return progress && progress.xp;
};

export type ExerciseOutline = {
  id: number;
  number: number;
  title: string;
};
export type ChapterExercisesProps = {
  chapterRef: number | string;
  courseRef: number | string;
  currentExerciseId?: number;
  disableTabbing?: boolean;
  exercises?: ExerciseOutline[];
  learningMode: LearningModeState;
  onExit: () => void;
  onFirstLinkRendered?: (element: HTMLElement | null) => void;
  progress?: unknown[];
};

const ChapterExercises: React.FC<ChapterExercisesProps> = ({
  chapterRef,
  courseRef,
  currentExerciseId,
  disableTabbing,
  exercises,
  learningMode,
  onExit,
  onFirstLinkRendered,
  progress,
}) => {
  const exs = isEmpty(exercises) ? (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Loader width="20" aria-label="Loading" />
    </div>
  ) : (
    map(exercises, (ex, index) => {
      const exerciseWasCompleted = isCompleted(progress && progress[index], ex);
      const xpWon =
        computeXp(
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          progress[index],
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{ title: s... Remove this comment to see the full error message
          ex.user && ex.user.currentXp,
          exerciseWasCompleted,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{ title: str... Remove this comment to see the full error message
          ex.xp,
          ex,
        ) || 0;
      return (
        <li
          data-cy={`outline-exercise-item${
            ex.id === currentExerciseId ? '-current' : ''
          }`}
          key={index}
        >
          <DCLink
            data-cy="outline-exercise"
            to={formatUrl(
              courseRef,
              chapterRef,
              ex.number,
              Map({ learningMode }),
              DEFAULT_LANGUAGE,
            )}
            tabIndex={disableTabbing ? -1 : undefined}
            onClick={onExit}
            // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
            ref={index === 0 ? onFirstLinkRendered : undefined}
            css={{
              display: 'flex',
              alignItems: 'center',
              height: tokens.sizing.medium,
              paddingLeft: tokens.spacing.medium,
              paddingRight: tokens.spacing.medium,
              gap: tokens.spacing.small,
              border: 0,
              textDecoration: 'none',
              backgroundColor:
                ex.id === currentExerciseId
                  ? tokens.colors.greyLight
                  : 'transparent',
              ':hover': {
                backgroundColor: tokens.colors.transparentGreySubtle,
              },
              ':focus-visible': {
                outline: `${tokens.borderWidth.medium} solid ${tokens.colors.blueDark}`,
                outlineOffset: -2,
              },
              transition: `background-color 75ms ease-out`,
            }}
          >
            <Avatar
              size="xsmall"
              variant={exerciseWasCompleted ? 'grey' : 'navy'}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ title: s... Remove this comment to see the full error message
              content={<ExerciseIcon iconName={ex.type} />}
            />
            <Text>{ex.title}</Text>
            <ChapterExerciseXp
              exerciseWasCompleted={exerciseWasCompleted}
              xpWon={xpWon}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{ title: str... Remove this comment to see the full error message
              exerciseTotalXp={ex.xp || 0}
            />
          </DCLink>
        </li>
      );
    })
  );

  return (
    <ul
      css={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
        paddingBottom: tokens.spacing.medium,
      }}
    >
      {exs}
    </ul>
  );
};

export default ChapterExercises;
