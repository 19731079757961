import { FeedbackMessage } from '@datacamp/le-shared-components';
import { AIAssistantProps } from '@datacamp/le-shared-components/types/Feedbacks/utils';
import TurndownService from 'turndown';

import config from '../../../../config';
import { generatePrediction, saveScore } from '../../../../helpers/ai/api';
import {
  sqlTableDefinitions,
  technologyVersions,
} from '../../../../helpers/ai/hardcodedPromptData';
import { isFeatureEnabled } from '../../../../helpers/featureFlags';

const turndownService = new TurndownService();

export type FeedbackMessageItem = Omit<FeedbackMessage, 'rating'> & {
  lastRunCode: string;
  ratedFeedback: boolean;
};

const AI_INCORRECT_SUBMISSIONS_ADDITION =
  '&nbsp;\n\n**Do you need additional hints?**\n\nIf you are stuck, you can ask our AI Assistant for exercise solution tips.';

export const getAiIncorrectSubmissionMarkdownBody = ({
  body,
  isUserEnabledForAiIncorrectSubmissions,
}: {
  body: string;
  isUserEnabledForAiIncorrectSubmissions: boolean;
}): string => {
  const markdownBody = turndownService.turndown(body);
  if (!isUserEnabledForAiIncorrectSubmissions) {
    return markdownBody;
  }
  return `${markdownBody}\n\n${AI_INCORRECT_SUBMISSIONS_ADDITION}`;
};

export const getAiProps = ({
  code,
  courseId,
  courseTitle,
  exerciseId,
  feedbackMessage,
  instructions,
  language,
  pec,
  setHintShown,
  setUserUsedAiErrorExplanation,
  setUserUsedAiIncorrectSubmissions,
  solution,
  userLanguage,
}: {
  code: string;
  courseId: number;
  courseTitle: string;
  exerciseId: number;
  feedbackMessage: FeedbackMessageItem;
  instructions: string;
  language: string;
  pec: string;
  setHintShown: (hintShown: boolean) => void;
  setUserUsedAiErrorExplanation: (value: boolean) => void;
  setUserUsedAiIncorrectSubmissions: (value: boolean) => void;
  solution: string;
  userLanguage: string;
}): AIAssistantProps => {
  const isProductionEnvironment = config.isProductionDomain();
  const getIsExperiment = (): boolean =>
    isFeatureEnabled('cp--error-explanation-ab-test');
  const tableDefinitions =
    language === 'sql' ? sqlTableDefinitions[courseId] : undefined;

  const technologyVersionsForCourse = technologyVersions[courseId] ?? '';
  const modelParams = {
    code,
    language,
    userLanguage,
    errorMessage: feedbackMessage.error || '',
    solution,
    courseTitle,
    pec,
    instructions,
    tableDefinitions,
    technologyVersions: technologyVersionsForCourse,
  };
  return {
    exerciseId,
    modelParams,
    generatePrediction,
    saveScore,
    setHintShown,
    getIsExperiment,
    isProductionEnvironment,
    setUserUsedAiIncorrectSubmissions,
    setUserUsedAiErrorExplanation,
  };
};
