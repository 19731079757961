/** @jsx jsx */
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

import { fadeIn, grow } from './keyframes';

const checkmarkCircleStyle = {
  transform: 'translate3d(0, 0, 0)',
};

const checkmarkCircleAnimation = {
  animation: `${fadeIn} 0.5s cubic-bezier(.36, .07, .19, .97) both`,
  animationDelay: '500ms',
};

const checkmarkIconStyle = {
  transform: 'translate3d(0, 0, 0)',
  transformOrigin: '22px 18px',
};

const checkmarkIconAnimation = {
  animation: `${grow} 0.82s cubic-bezier(.36, .07, .19, .97) both`,
  animationDelay: '500ms',
};

type Props = {
  step: 1 | 2 | 3;
};

const Checkmark: React.FC<Props> = ({ step }) => (
  <div
    css={{
      margin: tokens.spacing.medium,
      marginBottom: tokens.spacing.large,
    }}
  >
    <svg
      id="progress-visual"
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="48"
      viewBox="0 0 240 48"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill={tokens.colors.grey}
          d="M25.6666667,21.3333333 L205.666667,21.3333333 C206.771236,21.3333333 207.666667,22.2287638 207.666667,23.3333333 L207.666667,24.6666667 C207.666667,25.7712362 206.771236,26.6666667 205.666667,26.6666667 L25.6666667,26.6666667 C24.5620972,26.6666667 23.6666667,25.7712362 23.6666667,24.6666667 L23.6666667,23.3333333 C23.6666667,22.2287638 24.5620972,21.3333333 25.6666667,21.3333333 Z M215,21.3333333 L216.333333,21.3333333 C217.437903,21.3333333 218.333333,22.2287638 218.333333,23.3333333 L218.333333,24.6666667 C218.333333,25.7712362 217.437903,26.6666667 216.333333,26.6666667 L215,26.6666667 C213.895431,26.6666667 213,25.7712362 213,24.6666667 L213,23.3333333 C213,22.2287638 213.895431,21.3333333 215,21.3333333 Z M225.666667,21.3333333 L227,21.3333333 C228.104569,21.3333333 229,22.2287638 229,23.3333333 L229,24.6666667 C229,25.7712362 228.104569,26.6666667 227,26.6666667 L225.666667,26.6666667 C224.562097,26.6666667 223.666667,25.7712362 223.666667,24.6666667 L223.666667,23.3333333 C223.666667,22.2287638 224.562097,21.3333333 225.666667,21.3333333 Z M236.333333,21.3333333 L237.666667,21.3333333 C238.771236,21.3333333 239.666667,22.2287638 239.666667,23.3333333 L239.666667,24.6666667 C239.666667,25.7712362 238.771236,26.6666667 237.666667,26.6666667 L236.333333,26.6666667 C235.228764,26.6666667 234.333333,25.7712362 234.333333,24.6666667 L234.333333,23.3333333 C234.333333,22.2287638 235.228764,21.3333333 236.333333,21.3333333 Z"
        />

        <circle
          className="placeholder-1"
          cx="24"
          cy="24"
          r="21.5"
          fill="#FFF"
          stroke={tokens.colors.grey}
          strokeWidth="5"
        />
        <circle
          className="placeholder-2"
          cx="93.333"
          cy="24"
          r="21.5"
          fill="#FFF"
          stroke={tokens.colors.grey}
          strokeWidth="5"
        />
        <circle
          className="placeholder-3"
          cx="162.667"
          cy="24"
          r="21.5"
          fill="#FFF"
          stroke={tokens.colors.grey}
          strokeWidth="5"
        />

        <g
          css={{
            opacity: step >= 1 ? 1 : 0,
          }}
        >
          <circle
            css={
              step === 1
                ? [checkmarkCircleStyle, checkmarkCircleAnimation]
                : checkmarkCircleStyle
            }
            cx="24"
            cy="24"
            r="21.5"
            fill={tokens.colors.green}
            stroke={tokens.colors.green}
            strokeWidth="5"
          />

          <path
            css={
              step === 1
                ? [checkmarkIconStyle, checkmarkIconAnimation]
                : checkmarkIconStyle
            }
            fill={tokens.colors.white}
            d="M17.7466667,23.1253333 L21.072,27.2826667 L30.8933333,16.368 C31.8889186,15.2597486 33.5944153,15.1684147 34.7026667,16.164 C35.8109181,17.1595853 35.902252,18.8650819 34.9066667,19.9733333 L22.96,33.248 C22.4303349,33.8373302 21.6682334,34.1638142 20.8761975,34.1407003 C20.0841615,34.1175864 19.3423963,33.7472152 18.848,33.128 L13.536,26.4933333 C12.929449,25.7416033 12.7714074,24.7228211 13.1217037,23.8226574 C13.472,22.9224936 14.2771002,22.2785154 15.2322253,22.1345074 C16.1873503,21.9904994 17.1465338,22.3684696 17.7466667,23.1253333 Z"
          />
        </g>

        <g
          css={{
            opacity: step >= 2 ? 1 : 0,
          }}
          transform="translate(69.35, 0)"
        >
          <circle
            css={
              step === 2
                ? [checkmarkCircleStyle, checkmarkCircleAnimation]
                : checkmarkCircleStyle
            }
            cx="24"
            cy="24"
            r="21.5"
            fill={tokens.colors.green}
            stroke={tokens.colors.green}
            strokeWidth="5"
          />

          <path
            css={
              step === 2
                ? [checkmarkIconStyle, checkmarkIconAnimation]
                : checkmarkIconStyle
            }
            fill={tokens.colors.white}
            d="M17.7466667,23.1253333 L21.072,27.2826667 L30.8933333,16.368 C31.8889186,15.2597486 33.5944153,15.1684147 34.7026667,16.164 C35.8109181,17.1595853 35.902252,18.8650819 34.9066667,19.9733333 L22.96,33.248 C22.4303349,33.8373302 21.6682334,34.1638142 20.8761975,34.1407003 C20.0841615,34.1175864 19.3423963,33.7472152 18.848,33.128 L13.536,26.4933333 C12.929449,25.7416033 12.7714074,24.7228211 13.1217037,23.8226574 C13.472,22.9224936 14.2771002,22.2785154 15.2322253,22.1345074 C16.1873503,21.9904994 17.1465338,22.3684696 17.7466667,23.1253333 Z"
          />
        </g>

        <g
          css={{
            opacity: step >= 3 ? 1 : 0,
          }}
          transform="translate(138.7, 0)"
        >
          <circle
            css={
              step === 3
                ? [checkmarkCircleStyle, checkmarkCircleAnimation]
                : checkmarkCircleStyle
            }
            cx="24"
            cy="24"
            r="21.5"
            fill={tokens.colors.green}
            stroke={tokens.colors.green}
            strokeWidth="5"
          />

          <path
            css={
              step === 3
                ? [checkmarkIconStyle, checkmarkIconAnimation]
                : checkmarkIconStyle
            }
            fill={tokens.colors.white}
            d="M17.7466667,23.1253333 L21.072,27.2826667 L30.8933333,16.368 C31.8889186,15.2597486 33.5944153,15.1684147 34.7026667,16.164 C35.8109181,17.1595853 35.902252,18.8650819 34.9066667,19.9733333 L22.96,33.248 C22.4303349,33.8373302 21.6682334,34.1638142 20.8761975,34.1407003 C20.0841615,34.1175864 19.3423963,33.7472152 18.848,33.128 L13.536,26.4933333 C12.929449,25.7416033 12.7714074,24.7228211 13.1217037,23.8226574 C13.472,22.9224936 14.2771002,22.2785154 15.2322253,22.1345074 C16.1873503,21.9904994 17.1465338,22.3684696 17.7466667,23.1253333 Z"
          />
        </g>
      </g>
    </svg>
  </div>
);

export default Checkmark;
