import React from 'react';

const isFunction = (fn: any) => typeof fn === 'function';

const showOnCondition = (condition: any) => (WrappedComponent: any) => ({
  children,
  ...rest
}: any) => {
  if (isFunction(condition) && condition(rest)) {
    return <WrappedComponent {...rest}>{children}</WrappedComponent>;
  }
  return false;
};

export default showOnCondition;
