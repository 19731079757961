import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import BaseLayout from './BaseLayout';

const mapStateToProps = (state: any) => {
  return {
    mobileView: selectors.selectMobileView(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  showLoginModal: () =>
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.LOGIN })),

  setActiveTab: (parentKey: any, tabKey: any) =>
    dispatch(
      actions.updateActiveTab({ category: parentKey, activeKey: tabKey }),
    ),

  onItemClosed: (event: any) =>
    dispatch(
      actions.closeEditorTab({
        tabKey: event.origin.config.key,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
