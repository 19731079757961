/** @jsx jsx */
import { HTMLContent } from '@datacamp/le-shared-components';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

import AssignmentText from '../AssignmentText';

import Feedback from './Feedback/Feedback';

type Props = {
  className?: string;
  disableFeedback?: boolean;
  exercise: {
    instructions: string;
    language: string;
  };
};

const NormalInstructions: React.FC<Props> = ({
  className,
  disableFeedback,
  exercise,
}) => (
  <div
    className={className}
    css={{
      flex: 1,
      position: 'relative',
      padding: tokens.spacing.medium,
    }}
  >
    <AssignmentText>
      <HTMLContent
        extraClass="exercise--instructions__content"
        mathJaxEnabled
        html={exercise.instructions}
      />
    </AssignmentText>
    {/* @ts-expect-error fix by fixing type errors in Feedback */}
    {!disableFeedback && <Feedback language={exercise.language} />}
  </div>
);

export default NormalInstructions;
