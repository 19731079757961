/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkmark from '../Checkmark';
import XpBadge from '../XpBadge';

const onShowEventName = 'ds-milestone-display-2';

type Props = {
  onAdvance: (...args: any[]) => any;
  xp?: number;
};

const Step3: React.FC<Props> = ({ onAdvance, xp = 0 }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header>{t('OnboardingMilestonesStep3.header')}</Dialog.Header>
      {xp > 0 && <XpBadge xp={xp} />}
      <Dialog.Body>
        <Checkmark step={2} />
        <Paragraph>{t('OnboardingMilestonesStep3.text')}</Paragraph>
      </Dialog.Body>
      <Dialog.Footer data-cy={onShowEventName}>
        <Dialog.Button
          variant="secondary"
          onClick={onAdvance}
          data-cy="ds-milestone-clck-continue"
        >
          {t('OnboardingMilestonesStep3.button')}
        </Dialog.Button>
      </Dialog.Footer>
    </>
  );
};

export default Step3;
