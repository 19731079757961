/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { ChevronLeft } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from '../interfaces/State';
import { goBackToDashboard } from '../redux/actions/dashboard';
import { selectIsUserLoggedIn } from '../redux/selectors';

const linkStyle = {
  color: tokens.colors.navy,
  ':hover': {
    color: tokens.colors.navy,
  },
};

type Props = {
  redirectUrl: string;
};

const LinkToDashboard: React.FC<Props> = ({ redirectUrl }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // for logged out users we want the button to be a link with href for SEO purposes
  // for logged in users we want to keep the goBackToDashboard method, as it triggers surveys
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const href = isUserLoggedIn ? undefined : redirectUrl;
  const onClick = isUserLoggedIn
    ? () =>
        dispatch(
          goBackToDashboard({
            redirectUrl,
          }),
        )
    : undefined;

  return (
    <Dialog.Button
      css={linkStyle}
      variant="secondary"
      as="a"
      data-cy="outline-dashboard"
      href={href}
      onClick={onClick}
      iconLeft={<ChevronLeft />}
    >
      {t('LinkToDashboard.button')}
    </Dialog.Button>
  );
};

export default LinkToDashboard;
