/** @jsx jsx */
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import map from 'lodash/map';

import Instruction from '../NormalInstructions';
import TabInstructions from '../TabInstructions/TabInstructions';

import TabOfSubexercises from './TabOfSubExercises';

class BulletInstructions extends TabInstructions {
  renderTabs() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'number' does not exist on type '{ id: nu... Remove this comment to see the full error message
      currentSubExercise: { number },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ id: number... Remove this comment to see the full error message
      exercise: { id, language, subexercises },
      sessionIsBusy,
      updateActiveInstructionTab,
    } = this.props;
    // get the key of the current shown instruction
    const activeInstruction = number - 1;
    return map(subexercises, (subEx, key) => {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      const isActiveInstruction = activeInstruction === key; // is this sub exercise is currently shown to the user
      const isCompleted = subEx.completed && subEx.completed.completed;
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      const unlocked = subEx.unlocked || key === 0;
      const canClick = () => !isActiveInstruction && unlocked;
      const onClick = () =>
        canClick() && updateActiveInstructionTab({ activeKey: key });
      return (
        <li
          onClick={onClick}
          key={key}
          css={{
            backgroundColor: isActiveInstruction
              ? tokens.colors.white
              : tokens.colors.greyLight,
            borderBottom: `${tokens.borderWidth.thin} solid ${tokens.colors.white}`,
            display: 'flex',
            alignItems: 'flex-start',
            gap: tokens.spacing.medium,
            padding: tokens.spacing.medium,
            cursor: canClick() ? 'pointer' : 'default',
          }}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
          <TabOfSubexercises
            {...{
              CustomTag: 'div',
              subExercise: subEx,
              isCodeExecuting: sessionIsBusy,
              unlocked,
              active: isActiveInstruction,
              onClick,
              isCompleted,
              index: key,
            }}
          />
          <Instruction
            {...{
              exercise: {
                id,
                ...subEx,
                instructions: subEx.instructions,
                language,
              },
              disableFeedback: !isActiveInstruction,
            }}
            key={`${id}_${key}`}
            css={{ padding: 0 }}
          />
        </li>
      );
    });
  }

  render() {
    return (
      <div className="instructions--bullet bullet-steps">
        <div className="bullet-instructions-list">
          {/* Hide list bullets, when they are part of exercise markdown */}
          <ul
            css={{
              ul: {
                padding: 0,
                margin: 0,
                listStyle: 'none',
              },
              li: {
                margin: 0,
              },
            }}
          >
            {this.renderTabs()}
          </ul>
        </div>
      </div>
    );
  }
}

export default BulletInstructions;
