import { connect } from 'react-redux';

import * as actions from '../../../../redux/actions';
import * as selectors from '../../../../redux/selectors';

import TabInstructions from './TabInstructions';

const mapStateToProps = (state: any) => ({
  exercise: selectors.selectExercise(state).toJS(),
  currentSubExercise: selectors.selectCurrentSubExercise(state).toJS(),
  sessionIsBusy: selectors.isSessionBusy(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateActiveInstructionTab: ({ activeKey }: any) =>
    dispatch(actions.updateActiveInstructionTab({ activeKey })),

  onSelectChoice: (index: any) => (selection: any) =>
    dispatch(actions.chooseAnswer({ choice: selection, index })),
  onSubmitChoice: (settings: any) => dispatch(actions.executeCode(settings)),
  setMceChoicesFocus: (focused: any) =>
    dispatch(actions.setMceChoicesFocus({ focused })),
  onStartSession: (settings: any) =>
    dispatch(actions.epicStartSession(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabInstructions);
