/** @jsx jsx */
import { Badge } from '@datacamp/waffles/badge';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

type Props = {
  xp: number;
};

const XpBadge: React.FC<Props> = ({ xp }) => {
  return (
    <Badge
      variant="yellow"
      size="large"
      css={{
        position: 'absolute',
        top: tokens.spacing.large,
        right: tokens.spacing.large,
      }}
    >{`+${xp}XP`}</Badge>
  );
};

export default XpBadge;
