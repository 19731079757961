/** @jsx jsx */
import { HTMLContent } from '@datacamp/le-shared-components';
import { Heading } from '@datacamp/waffles/heading';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

import AssignmentText from './AssignmentText';

type Props = {
  assignment: string;
  title: string;
};

const Assignment: React.FC<Props> = ({ assignment, title }) => (
  <div
    css={{
      position: 'relative',
      padding: tokens.spacing.medium,
    }}
  >
    <Heading size="xxlarge">{title}</Heading>
    <AssignmentText>
      <HTMLContent mathJaxEnabled html={assignment} />
    </AssignmentText>
  </div>
);

export default Assignment;
