/** @jsx jsx */
import { HTMLContent } from '@datacamp/le-shared-components';
import { Button } from '@datacamp/waffles/button';
import { hexToRgba } from '@datacamp/waffles/helpers';
import { Cross } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import Shortcuts from '../../../containers/AssignmentShortcuts';
import trackABExperiment from '../../../helpers/trackABExperiment';
import { useDispatch, useSelector } from '../../../interfaces/State';
import * as actions from '../../../redux/actions';
import * as selectors from '../../../redux/selectors';

import ExerciseAiIncorrectSubmissionRating from './ExerciseAiIncorrectSubmissionRating';
import ExerciseCompletedContinueButton from './ExerciseCompletedContinueButton';
import ExerciseCompletedRateContent from './ExerciseCompletedRateContent';
import ExerciseCompletedXp from './ExerciseCompletedXp';

type ExerciseCompletedProps = {
  id: number;
  message: string;
};

const ExerciseCompleted: React.FC<ExerciseCompletedProps> = ({
  id,
  message,
}) => {
  const dispatch = useDispatch();

  // Redux selectors
  const canRateChapter = useSelector(selectors.selectCanRateChapter);
  const shouldSeeExerciseRating = useSelector(
    selectors.selectShouldSeeExerciseRating,
  );

  const isReducedOutlineCourse = useSelector(
    selectors.selectIsReducedOutlineCourse,
  );
  const exerciseIndex = useSelector(selectors.selectExerciseIndex);
  const currentChapterNbExercises = useSelector(
    selectors.selectCurrentChapterNbExercises,
  );

  const userId = useSelector(selectors.selectUserSettings).get('id');
  const courseId = useSelector(selectors.selectCourse).get('id');
  const { nextExercise: nextExPath } = useSelector(
    selectors.selectNeighborsExercises,
  );
  const userProgress = useSelector(selectors.selectGlobalUserProgress);

  const xpWon = useSelector(selectors.selectCurrentExerciseXpWon);

  const userUsedAiIncorrectSubmissions = useSelector(
    selectors.selectUserUsedAiIncorrectSubmissions,
  );
  const userUsedAiErrorExplanation = useSelector(
    selectors.selectUserUsedAiErrorExplanation,
  );

  // Derived state

  // hide for last exercise of LTI exams
  const shouldSeeContinueButton = useMemo(
    () =>
      !(
        isReducedOutlineCourse &&
        exerciseIndex + 1 === currentChapterNbExercises
      ),
    [currentChapterNbExercises, exerciseIndex, isReducedOutlineCourse],
  );

  const shouldSeeAiIncorrectSubmissionsRating = useMemo(() => {
    const userUsedAiIncorrectSubmissionsButNotErrorExplanation =
      userUsedAiIncorrectSubmissions && !userUsedAiErrorExplanation;

    return (
      userUsedAiIncorrectSubmissionsButNotErrorExplanation &&
      !shouldSeeExerciseRating
    );
  }, [
    shouldSeeExerciseRating,
    userUsedAiErrorExplanation,
    userUsedAiIncorrectSubmissions,
  ]);

  // Callbacks
  const goToNextExercise = useCallback(() => {
    dispatch(actions.nextInternalExercise());
  }, [dispatch]);

  const showChapterRating = useCallback(() => {
    const bucket = trackABExperiment('skipChapterRating', courseId, userId);

    if (bucket === 'control') {
      return dispatch(
        actions.showModal({
          modal: {
            ...selectors.MODAL_TYPE.CHAPTER_RATING,
            onFinish: () => {
              dispatch(actions.removeModal());
              goToNextExercise();
            },
          },
        }),
      );
    }
    return goToNextExercise();
  }, [courseId, dispatch, goToNextExercise, userId]);

  const onSubmitRating = useCallback(
    (rating: any) => {
      dispatch(
        actions.epicSubmitExerciseRating({
          rating,
          nextPath: canRateChapter ? null : nextExPath,
        }),
      );

      if (canRateChapter) {
        showChapterRating();
      }
    },
    [canRateChapter, dispatch, nextExPath, showChapterRating],
  );

  const onContinue = useCallback(() => {
    if (canRateChapter) {
      showChapterRating();
    } else {
      goToNextExercise();
    }
  }, [canRateChapter, goToNextExercise, showChapterRating]);

  const onClose: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(actions.hideCompletedExercise({ id }));
    },
    [dispatch, id],
  );

  // Side effects
  useEffect(
    () => () => {
      if (userProgress.chapter.completed || userProgress.course.completed) {
        dispatch(actions.firstTimeCompletedChapters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'auto',
        background: hexToRgba(tokens.colors.navy, 0.95),
        borderRadius: tokens.borderRadius.medium,
      }}
      data-cy="exercise-completed-pane"
    >
      <Button
        variant="plain"
        icon={<Cross />}
        aria-label="Close"
        onClick={onClose}
        inverted
        css={{
          color: tokens.colors.white,
          position: 'absolute',
          top: tokens.spacing.small,
          right: tokens.spacing.small,
        }}
      />
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          flexGrow: 1,
          maxWidth: 500,
        }}
      >
        <ExerciseCompletedXp xpWon={xpWon ?? 0} />
        <Text
          as="div"
          css={{
            marginBottom: tokens.spacing.medium,
            paddingLeft: tokens.spacing.medium,
            paddingRight: tokens.spacing.medium,
            color: tokens.colors.white,
            lineHeight: tokens.lineHeights.relaxed,
            textAlign: 'center',
            'p > code': {
              mixBlendMode: 'normal',
            },
          }}
        >
          <HTMLContent mathJaxEnabled customTag="p" html={message} />
        </Text>
        {shouldSeeExerciseRating && (
          <ExerciseCompletedRateContent onSubmit={onSubmitRating} />
        )}
        {shouldSeeContinueButton && (
          <ExerciseCompletedContinueButton onNext={onContinue} />
        )}
        {shouldSeeAiIncorrectSubmissionsRating && (
          <ExerciseAiIncorrectSubmissionRating key={id} />
        )}
      </div>
      {shouldSeeExerciseRating && <Shortcuts />}
    </div>
  );
};

export default ExerciseCompleted;
