/** @jsx jsx */
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { Checkmark } from '@datacamp/waffles/icon';
import { darkThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import { useLottie } from 'lottie-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import shimmer from './animations/shimmer.json';

const FlashShimmer = ({ width }: { width: string }) => {
  const { View, setSpeed } = useLottie(
    {
      animationData: shimmer,
      autoplay: true,
      initialSegment: [0, 300],
      loop: true,
    },
    { width },
  );

  useEffect(() => {
    setSpeed(2);
  }, [setSpeed]);
  return View;
};

const isInStreak = ({
  index,
  streakLengthInDays,
  todayIndex,
}: {
  index: number;
  streakLengthInDays: number;
  todayIndex: number;
}): boolean => {
  if (index > todayIndex) {
    return false;
  }
  const streakLengthBeforeToday = streakLengthInDays - 1;
  return todayIndex - index <= streakLengthBeforeToday;
};

const getMobileSizes = ({ days }: { days: number }) => {
  return days < 1000
    ? { flash: '92px', font: '120px' }
    : { flash: '78px', font: '100px' };
};

const StreakDay = ({
  day,
  streakActive,
  today = false,
}: {
  day: string;
  streakActive: boolean;
  today?: boolean;
}): JSX.Element => (
  <div
    css={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: tokens.spacing.xsmall,
    }}
  >
    <div
      css={{
        color: today
          ? tokens.colors.greyLight
          : tokens.colors.navySubtleTextOnLight,
      }}
    >
      {day[0]}
    </div>
    <div
      css={{
        alignItems: 'center',
        background: streakActive
          ? tokens.colors.yellow
          : tokens.colors.navyLight,
        border: `${tokens.borderWidth.medium} solid ${
          today ? tokens.colors.navySubtleTextOnLight : tokens.colors.navyMedium
        }`,
        borderRadius: tokens.borderRadius.circle,
        display: 'flex',
        height: 28,
        justifyContent: 'center',
        margin: `0 2px`,
        width: 28,
      }}
    >
      {streakActive && <Checkmark></Checkmark>}
    </div>
  </div>
);

type StreakWidgetProps = {
  isMobile: boolean;
  streakLengthInDays: number;
};

export const StreakWidget: React.FC<StreakWidgetProps> = ({
  isMobile,
  streakLengthInDays,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  // Sunday is 0, we want it to be 6
  const todayIndex = (new Date().getDay() - 1 + 7) % 7;
  const dateTimeFormat = new Intl.DateTimeFormat(language, {
    weekday: 'short',
  });
  const days = [...Array(7).keys()].map((day) =>
    dateTimeFormat
      .format(new Date(1970, 1, day + 2))
      .charAt(0)
      .toUpperCase(),
  );

  const flashDimension = isMobile
    ? getMobileSizes({ days: streakLengthInDays }).flash
    : '106px';

  return (
    <div
      css={[
        darkThemeStyle,
        css({
          borderRadius: '8px',
          border: `${tokens.borderWidth.medium} solid ${tokens.colors.navySubtleTextOnLight}`,
        }),
      ]}
    >
      <div
        css={{
          alignItems: 'center',
          color: tokens.colors.yellow,
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          padding: isMobile ? tokens.spacing.small : tokens.spacing.medium,
          textAlign: 'center',
        }}
      >
        <FlashShimmer width={flashDimension} />
        <Heading
          css={{
            color: tokens.colors.greyLight,
            fontSize: isMobile
              ? getMobileSizes({ days: streakLengthInDays }).font
              : '140px',
            margin: 0,
          }}
        >
          {streakLengthInDays}
        </Heading>
      </div>
      <div
        css={{
          background: tokens.colors.navyMedium,
          borderRadius: '0 0 8px 8px',
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          [mediaQuery.aboveSmall]: {
            gap: tokens.spacing.medium,
          },
          padding: isMobile ? tokens.spacing.medium : tokens.spacing.large,
          paddingTop: tokens.spacing.medium,
        }}
      >
        {days.map((day, index) => (
          <StreakDay
            day={day}
            key={index}
            streakActive={
              todayIndex === index ||
              isInStreak({
                index,
                streakLengthInDays,
                todayIndex,
              })
            }
            today={todayIndex === index}
          />
        ))}
      </div>
    </div>
  );
};
