/* eslint-disable filenames/match-exported */
/** @jsx jsx */
import { useShortcut } from '@datacamp/le-shared-components';
import { Button } from '@datacamp/waffles/button';
import { hexToRgba, mediaQuery } from '@datacamp/waffles/helpers';
import { ArrowLeft, ArrowRight, Menu } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { isTeachPreview } from '../../../helpers/isTeachPreview';
import {
  notifyHostApplicationToShowContentOutline,
  notifyHostApplicationToShowNextExercise,
  notifyHostApplicationToShowPreviousExercise,
} from '../../../helpers/learningMode';
import { navigateTo } from '../../../helpers/navigation';
import { useDispatch, useSelector } from '../../../interfaces/State';
import * as actions from '../../../redux/actions';
import * as selectors from '../../../redux/selectors';
import CourseOutlineModal from '../../CourseOutline/CourseOutlineModal';
import LinkButton from '../../LinkButton';

type Props = {
  history: any;
  simpleButton?: boolean;
};

const centerButtonStyle = {
  color: tokens.colors.navy,
  [mediaQuery.aboveMedium]: {
    borderRadius: 0,
    ':after': {
      borderRadius: 0,
    },
  },
};

// Fix global CSS styles leaking
const baseNavButtonStyle = {
  ':hover': {
    color: tokens.colors.navy,
    borderBottomColor: hexToRgba(tokens.colors.navy, tokens.opacity.high),
  },
  display: 'none',
  [mediaQuery.aboveMedium]: {
    display: 'inline-flex',
  },
};

const prevButtonStyle = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: 0,
  ':after': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
};

const nextButtonStyle = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderLeft: 0,
  ':after': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const CourseNavigation: React.FC<Props> = ({ history, simpleButton }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [outlineShown, setOutlineShown] = useState(false);

  const {
    nextExercise: nextPath,
    previousExercise: previousPath,
  } = useSelector(selectors.selectNeighborsExercises);

  const learningMode = useSelector(selectors.selectLearningMode);
  const isCaseStudy = useSelector(selectors.selectCourseCaseStudy ?? false);

  const isSingleExerciseMode = learningMode === 'singleExercise';

  const showOutline = (event?: any): void => {
    if (event) {
      event.preventDefault();
    }
    if (isSingleExerciseMode) {
      notifyHostApplicationToShowContentOutline();
      return;
    }
    setOutlineShown(true);
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.COURSE_OUTLINE }));
  };

  const previousExercise = (event?: any): void => {
    if (isSingleExerciseMode) {
      if (event) {
        event.preventDefault();
      }
      notifyHostApplicationToShowPreviousExercise();
    }
  };
  const nextExercise = (event?: any): void => {
    if (isSingleExerciseMode) {
      if (event) {
        event.preventDefault();
      }
      notifyHostApplicationToShowNextExercise();
    }
  };
  const onPreviousExerciseKeyboardShortcut = (): void => {
    if (isSingleExerciseMode) {
      notifyHostApplicationToShowPreviousExercise();
      return;
    }
    if (previousPath) {
      navigateTo(history, previousPath);
    }
  };
  const onNextExerciseKeyboardShortcut = (): void => {
    if (isSingleExerciseMode) {
      notifyHostApplicationToShowNextExercise();
      return;
    }
    if (nextPath) {
      navigateTo(history, nextPath);
    }
  };

  const titleForLearningMode = (): string => {
    if (isCaseStudy) {
      return t('CourseNavigation.caseStudyTitle');
    }
    switch (learningMode) {
      case 'singleExercise':
        return t('CourseNavigation.singleExerciseTitle');
      case 'singleChapter':
        return t('CourseNavigation.singleChapterTitle');
      case 'course':
      default:
        return t('CourseNavigation.courseTitle');
    }
  };

  useShortcut({
    handler: () => showOutline(),
    shortcut: { ctrlKey: true, key: 'o' },
  });
  useShortcut({
    handler: onPreviousExerciseKeyboardShortcut,
    shortcut: { ctrlKey: true, key: 'j' },
  });
  useShortcut({
    handler: onNextExerciseKeyboardShortcut,
    shortcut: { ctrlKey: true, key: 'k' },
  });

  const isPreview = isTeachPreview(history.location.pathname);

  return (
    <nav>
      {!simpleButton && (
        <LinkButton
          data-cy="header-previous"
          // @ts-expect-error fix this by fixing the type errors of LinkButton
          onClick={previousExercise}
          href={!isSingleExerciseMode ? previousPath : null}
          disabled={previousPath === null || isPreview}
          aria-label={t('CourseNavigation.previousButtonLabel')}
          tooltipText={t('CourseNavigation.previousButtonTooltip')}
          icon={<ArrowLeft />}
          variant="secondary"
          css={[baseNavButtonStyle, prevButtonStyle]}
        />
      )}
      <Tooltip content="Ctrl+O">
        <Button
          data-cy="header-outline"
          css={{
            ...centerButtonStyle,
            ...(simpleButton ? { border: 'none', width: '100%' } : {}),
          }}
          disabled={isPreview}
          onClick={showOutline}
          variant="secondary"
          iconLeft={<Menu />}
        >
          {titleForLearningMode()}
        </Button>
      </Tooltip>
      {!simpleButton && (
        <LinkButton
          data-cy="header-next"
          // @ts-expect-error fix this by fixing the type errors of LinkButton
          onClick={nextExercise}
          href={!isSingleExerciseMode ? nextPath : null}
          disabled={nextPath === null || isPreview}
          aria-label={t('CourseNavigation.nextButtonLabel')}
          tooltipText={t('CourseNavigation.nextButtonTooltip')}
          icon={<ArrowRight />}
          variant="secondary"
          css={[baseNavButtonStyle, nextButtonStyle]}
        />
      )}
      <CourseOutlineModal
        showOutline={outlineShown}
        onClose={() => setOutlineShown(false)}
      />
    </nav>
  );
};

export default withRouter(CourseNavigation);
