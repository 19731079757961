/** @jsx jsx */
import { Loader } from '@datacamp/waffles/loader';
import { jsx } from '@emotion/react';
import React from 'react';

import { UiTheme } from './ThemeToggler';

type Props = {
  uiTheme?: UiTheme;
};

const DCSpinner: React.FC<Props> = ({ uiTheme }) => (
  <div
    css={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Loader aria-label="Loading" inverted={uiTheme === 'DARK'} width="50" />
  </div>
);

export default DCSpinner;
