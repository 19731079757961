/** @jsx jsx */
import { AlpaNavbar } from '@datacamp/alpa-navbar';
import { constants as cloudExerciseConstants } from '@datacamp/cloud-exercise-core';
import { constants as pureMultipleChoiceExerciseConstants } from '@datacamp/pure-multiple-choice-exercise-core';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { Badge } from '@datacamp/waffles/badge';
import { Button } from '@datacamp/waffles/button';
import { Card } from '@datacamp/waffles/card';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import {
  Attention,
  Document,
  Edit,
  Mobile,
  More,
  VideoCamera,
} from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import includes from 'lodash/includes';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import startCase from 'lodash/startCase';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { getDescriptionPageParentBreadcrumb } from '../../helpers/getDescriptionPageParentBreadcrumb';
import { Technology } from '../../helpers/technology';
import { Topic } from '../../helpers/topic';
import { getModalHooked } from '../../helpers/user';
import { getUrlWithLanguage } from '../../i18n';
import { useDispatch, useSelector } from '../../interfaces/State';
import { goBackToDashboard } from '../../redux/actions';
import { CategoryPages } from '../../redux/middlewares/boot/getCategoryPagesAsPromise';
import { LearningModeState } from '../../redux/reducers/learningMode';
import { StreakInfoState } from '../../redux/reducers/streakInfo';
import { selectIsUserLoggedIn } from '../../redux/selectors';
import { headerIconButton } from '../../styles/campus/buttonOverrides';
import { useCourseNotes } from '../CourseNotes/hooks/useCourseNotes';
import GuidesHeader from '../Guides/GuidesHeader';
import MobileButton from '../MobilePopup/MobileButton';
import PeekVideoButton from '../PeekVideoButton';
import XPCounter from '../XPCounter';

import BackendStatus from './BackendStatus';
import CourseNavigation from './CourseNavigation';
import { NotesPopup } from './NotesPopup';

const exercisesWithoutSessionStatus = [
  cloudExerciseConstants.exercises.CLOUD_EXERCISE,
  videoExerciseConstants.exercises.VIDEO_EXERCISE,
  pureMultipleChoiceExerciseConstants.exercises.PURE_MULTIPLE_CHOICE_EXERCISE,
];

const betaBadgeStyle = {
  marginRight: tokens.spacing.xsmall,
};

export type Props = {
  activeSlide: (opts: { category: string }) => void;
  categoryPages: CategoryPages;
  courseId?: number;
  courseSlug?: string;
  courseTitle?: string;
  exerciseType?: string;
  isCaseStudy?: boolean;
  isMobileButtonVisible: boolean;
  isPeekVideoButtonVisible: boolean;
  isSlide: boolean;
  learningMode: LearningModeState;
  minimal?: boolean;
  onClickMobileButton: (...args: any[]) => any;
  onClickOnIssueReporter: (...args: any[]) => any;
  onClickPeekVideoButton: (...args: any[]) => any;
  showLoginModal: (...args: any[]) => any;
  showNoteTaking: boolean;
  streakInfo: StreakInfoState;
  technology: Technology;
  topic: Topic;
  user?: { status: string };
};

const HamburgerMenu: React.FC<
  Props & { onClickShowSlides: () => void; onClickTakeNotes: () => void }
> = ({
  exerciseType,
  isMobileButtonVisible,
  isPeekVideoButtonVisible,
  isSlide,
  onClickMobileButton,
  onClickOnIssueReporter,
  onClickPeekVideoButton,
  onClickShowSlides,
  onClickTakeNotes,
  showLoginModal,
  showNoteTaking,
  user,
}) => {
  const hookedFn = getModalHooked(user, null, showLoginModal);
  const { isAboveSmall } = useMediaQuery();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['translation', 'glossary']);
  return (
    <div css={{ position: 'relative' }}>
      <Button
        variant="secondary"
        css={{ border: 'none' }}
        aria-label="course-menu"
        onClick={() => setOpen(!open)}
      >
        <More />
      </Button>
      {
        <Card
          disableHoverEffect
          aria-hidden={!open}
          css={{
            position: 'absolute',
            top: 32,
            right: 0,
            padding: tokens.spacing.small,
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '90dvw',
            zIndex: tokens.zIndex.popup,
            'button span': {
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: tokens.fontWeights.regular,
              width: '100%',
            },
            ...(open
              ? { width: 260 }
              : {
                  width: 0,
                  height: 0,
                  overflow: 'hidden',
                }),
          }}
        >
          {/* @ts-expect-error fix by fixing type errors in CourseNavigation */}
          {!isAboveSmall && <CourseNavigation simpleButton={true} />}
          <GuidesHeader exerciseType="MarkdownExercise" />
          {isSlide && (
            <Button
              variant="plain"
              data-cy="header-slides"
              onClick={onClickShowSlides}
              iconLeft={<Document size="small" />}
              css={headerIconButton}
            >
              {t('Header.showSlides')}
            </Button>
          )}
          {isPeekVideoButtonVisible && (
            <Button
              data-cy="header-video"
              onClick={hookedFn || onClickPeekVideoButton}
              aria-label={t('PeekVideoButton.label')}
              css={headerIconButton}
              iconLeft={<VideoCamera size="small" />}
              variant="plain"
            >
              {t('PeekVideoButton.label')}
            </Button>
          )}
          {showNoteTaking && (
            <Button
              variant="plain"
              data-cy="header-notes"
              onClick={onClickTakeNotes}
              iconLeft={<Edit size="small" />}
              css={headerIconButton}
            >
              {t('Header.takeNotes')}
            </Button>
          )}
          {isMobileButtonVisible && (
            <Button
              data-cy="header-mobile"
              onClick={hookedFn || onClickMobileButton}
              css={headerIconButton}
              iconLeft={<Mobile size="small" />}
              variant="plain"
            >
              {t('MobilePopup.MobileButton.label')}
            </Button>
          )}
          <Button
            variant="plain"
            data-cy="header-issue"
            data-test-id="header-report-issue-button"
            onClick={hookedFn || onClickOnIssueReporter}
            iconLeft={<Attention size="small" />}
            css={headerIconButton}
          >
            {t('Header.provideFeedback')}
          </Button>

          {!includes(exercisesWithoutSessionStatus, exerciseType) && (
            <>
              <hr
                css={{
                  margin: tokens.spacing.small,
                  color: tokens.colors.navy,
                  opacity: 0.15,
                  borderBottom: 'none',
                }}
              />
              <div
                css={{
                  padding: tokens.spacing.small,
                  paddingLeft: tokens.spacing.medium,
                  paddingRight: tokens.spacing.medium,
                }}
              >
                <BackendStatus showText={true} />
              </div>
            </>
          )}
        </Card>
      }
    </div>
  );
};

const Header: React.FC<Props> = ({
  activeSlide,
  categoryPages,
  courseId,
  courseSlug,
  courseTitle,
  exerciseType,
  isCaseStudy,
  isMobileButtonVisible,
  isPeekVideoButtonVisible,
  isSlide,
  learningMode,
  minimal,
  onClickMobileButton,
  onClickOnIssueReporter,
  onClickPeekVideoButton,
  showLoginModal,
  showNoteTaking,
  streakInfo,
  technology,
  topic,
  user,
}) => {
  const loggedOut = user?.status !== 'login';
  const {
    i18n: { language },
    t,
  } = useTranslation(['translation', 'glossary']);
  const { isAboveMedium, isAboveSmall } = useMediaQuery();

  const hookedFn = getModalHooked(user, null, showLoginModal);

  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const onClickShowSlides =
    hookedFn ||
    (() =>
      activeSlide({
        category:
          exerciseType === 'MarkdownExercise'
            ? 'outputMarkdownTabs'
            : 'consoleTabs',
      }));

  const {
    onClosePopup,
    openCourseNotes,
    showCourseNotesPopup,
  } = useCourseNotes();
  const onClickTakeNotes = hookedFn || openCourseNotes;

  let rightContent = (
    <HamburgerMenu
      topic={topic}
      technology={technology}
      categoryPages={categoryPages}
      activeSlide={activeSlide}
      isMobileButtonVisible={isMobileButtonVisible}
      isPeekVideoButtonVisible={isPeekVideoButtonVisible}
      isSlide={isSlide}
      onClickMobileButton={onClickMobileButton}
      onClickOnIssueReporter={onClickOnIssueReporter}
      onClickPeekVideoButton={onClickPeekVideoButton}
      showLoginModal={showLoginModal}
      showNoteTaking={showNoteTaking}
      user={user}
      learningMode={learningMode}
      streakInfo={streakInfo}
      onClickTakeNotes={onClickTakeNotes}
      onClickShowSlides={onClickShowSlides}
    />
  );
  if (minimal) {
    rightContent = <></>;
  } else if (isAboveMedium) {
    rightContent = (
      <nav css={extraButtonsContainerStyle}>
        {!isNil(courseId) && includes([21701], courseId) && (
          <Badge variant="pink" size="large" css={betaBadgeStyle}>
            {t('Header.beta')}
          </Badge>
        )}
        {isAboveMedium && streakInfo.type === 'StreakKnown' && (
          <XPCounter xp={streakInfo.dailyXp} />
        )}
        <GuidesHeader exerciseType="MarkdownExercise" />
        {!includes(exercisesWithoutSessionStatus, exerciseType) && (
          <BackendStatus />
        )}
        {isSlide && (
          <Tooltip content={t('Header.showSlides')} placement="bottomRight">
            <Button
              variant="plain"
              data-cy="header-slides"
              onClick={onClickShowSlides}
              aria-label={t('Header.showSlides')}
              icon={<Document />}
              css={headerIconButton}
            />
          </Tooltip>
        )}
        {isPeekVideoButtonVisible && (
          <PeekVideoButton onClick={hookedFn || onClickPeekVideoButton} />
        )}
        {showNoteTaking && (
          <>
            <Tooltip content={t('Header.takeNotes')} placement="bottomRight">
              <Button
                variant="plain"
                data-cy="header-notes"
                onClick={onClickTakeNotes}
                aria-label={t('Header.takeNotes')}
                icon={<Edit />}
                css={headerIconButton}
              />
            </Tooltip>
            {showCourseNotesPopup && <NotesPopup onClose={onClosePopup} />}
          </>
        )}
        {isMobileButtonVisible && (
          <MobileButton onClick={hookedFn || onClickMobileButton} />
        )}
        <Tooltip content={t('Header.provideFeedback')} placement="bottomRight">
          <Button
            variant="plain"
            data-cy="header-issue"
            data-test-id="header-report-issue-button"
            onClick={hookedFn || onClickOnIssueReporter}
            aria-label={t('Header.provideFeedback')}
            icon={<Attention />}
            css={headerIconButton}
          />
        </Tooltip>
      </nav>
    );
  }

  // for logged out users we want the logo to be a link with href for SEO purposes
  // for logged in users we want to keep the goBackToDashboard method, as it triggers surveys
  const onClickLogo = isUserLoggedIn
    ? () => {
        dispatch(
          goBackToDashboard({
            redirectUrl: config.urls.datacamp,
          }),
        );
      }
    : undefined;

  const centerContent = isAboveSmall && <CourseNavigation />;

  if (loggedOut) {
    const coursesBreadcrumb = getDescriptionPageParentBreadcrumb({
      categoryPages,
      technology,
      topic,
    });

    const datacampUrl = getUrlWithLanguage(config.urls.datacamp, language);
    return (
      <HeaderNavbar
        centerContent={centerContent}
        courseSlug={courseSlug}
        courseTitle={courseTitle}
        dashboardUrl={datacampUrl}
        loggedOut={loggedOut}
        coursesBreadcrumb={coursesBreadcrumb}
        onClickLogo={onClickLogo}
        rightContent={rightContent}
        isCaseStudy={isCaseStudy}
        learningMode={learningMode}
      ></HeaderNavbar>
    );
  }

  const learnUrl = config.urls.learn;
  return (
    <div
      css={{
        'li:last-of-type a span': {
          maxWidth: isAboveMedium ? undefined : 'calc(100dvw - 190px)',
        },
      }}
    >
      <HeaderNavbar
        centerContent={centerContent}
        courseSlug={courseSlug}
        courseTitle={courseTitle}
        dashboardUrl={learnUrl}
        loggedOut={loggedOut}
        onClickLogo={onClickLogo}
        rightContent={rightContent}
        isCaseStudy={isCaseStudy}
        learningMode={learningMode}
      ></HeaderNavbar>
    </div>
  );
};

export type HeaderNavbarProps = {
  centerContent: React.ReactNode;
  coursesBreadcrumb?: { link: string; name: string } | null;
  courseSlug?: string;
  courseTitle?: string;
  dashboardUrl: string;
  isCaseStudy?: boolean;
  learningMode: LearningModeState;
  loggedOut: boolean;
  onClickLogo: (() => void) | undefined;
  rightContent: React.ReactNode;
};

export const HeaderNavbar: React.FC<HeaderNavbarProps> = ({
  centerContent,
  courseSlug,
  courseTitle,
  coursesBreadcrumb,
  dashboardUrl,
  isCaseStudy,
  learningMode,
  loggedOut,
  onClickLogo,
  rightContent,
}) => {
  const { t } = useTranslation();

  const contentType = isCaseStudy ? 'projects' : 'courses';
  const contentTypeTranslationKey = isCaseStudy
    ? 'glossary:project'
    : 'glossary:course';

  const extraProps = learningMode === 'singleExercise' ? { onClickLogo } : {};

  const loggedInOverviewUrl = `${dashboardUrl}/${contentType}`;

  const loggedOutPathSuffix =
    coursesBreadcrumb?.link ||
    `/courses-all${isCaseStudy ? '?tab=projects' : ''}`;
  const loggedOutOverviewUrl = `${dashboardUrl}${loggedOutPathSuffix}`;

  const backToOverviewUrl = loggedOut
    ? loggedOutOverviewUrl
    : loggedInOverviewUrl;

  return (
    <AlpaNavbar
      href={config.urls.datacamp}
      centerContent={centerContent}
      rightContent={rightContent}
      breadcrumbs={[
        {
          text: t('glossary:products.learn'),
          tooltip: t('Header.backToDashboard'),
          url: dashboardUrl,
          dataTrackId: 'alpa-navbar-breadcrumb-learn',
        },
        {
          text: startCase(t(contentTypeTranslationKey, { count: 2 })),
          tooltip: t(
            isCaseStudy ? 'Header.backToProjects' : 'Header.backToCourses',
          ),
          url: backToOverviewUrl,
          dataTrackId: 'alpa-navbar-breadcrumb-courses',
        },
        {
          text:
            courseTitle ??
            startCase(t(contentTypeTranslationKey, { count: 1 })),
          tooltip: t(
            isCaseStudy
              ? 'Header.backToProjectPage'
              : 'Header.backToCoursePage',
          ),
          url: `${dashboardUrl}/${contentType}/${courseSlug}`,
          dataTrackId: 'alpa-navbar-breadcrumb-course',
        },
      ]}
      {...extraProps}
    />
  );
};

const extraButtonsContainerStyle = {
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
} as const;

export default Header;
