import React from 'react';

import DCSpinner from '../DCSpinner';

type Props = {
  code?: string;
};

const ExerciseWaiting: React.FC<Props> = ({ code = '' }) => (
  <div className="exercise-waiting">
    <DCSpinner />
    <noscript>{code}</noscript>
  </div>
);

export default ExerciseWaiting;
