/** @jsx jsx */
import { ErrorBoundary } from '@datacamp/le-shared-components';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import raven from 'raven-js';
import React, { Suspense } from 'react';

import DCSpinner from '../DCSpinner';

const monacoImporter = () =>
  import(/* webpackChunkName: "monaco" */ './MonacoCodeEditor');

const MonacoCodeEditor = React.lazy(monacoImporter);

const CodeEditorLoader = (props: any) => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        ...(props.uiTheme === 'DARK' && {
          backgroundColor: tokens.colors.navy,
        }),
      }}
    >
      <ErrorBoundary onErrorReported={raven.captureException}>
        <Suspense fallback={<DCSpinner uiTheme={props.uiTheme} />}>
          <MonacoCodeEditor {...props} key={props.id} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default CodeEditorLoader;
