/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import isString from 'lodash/isString';
import size from 'lodash/size';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const onShowEventName = 'ds-milestone-display-0';

const decorBox1Style = {
  backgroundColor: tokens.colors.pink,
  width: 200,
  height: 18,
  left: 0,
  top: 0,
  position: 'absolute',
} as const;

const decorBox2Style = {
  backgroundColor: tokens.colors.purple,
  width: 100,
  height: 18,
  left: 0,
  top: 18,
  position: 'absolute',
} as const;

const decorBox3Style = {
  backgroundColor: tokens.colors.orange,
  width: 100,
  height: 18,
  right: 0,
  bottom: 18,
  position: 'absolute',
} as const;

const decorBox4Style = {
  backgroundColor: tokens.colors.blue,
  width: 200,
  height: 18,
  right: 0,
  bottom: 0,
  position: 'absolute',
} as const;

type Props = {
  firstName?: string;
  onAdvance: (...args: any[]) => any;
};

const Step1: React.FC<Props> = ({ firstName, onAdvance }) => {
  const { t } = useTranslation();

  const welcomeMessage =
    isString(firstName) && size(firstName) < 15
      ? t('OnboardingMilestonesStep1.headerWithName', { name: firstName })
      : t('OnboardingMilestonesStep1.header');

  return (
    <>
      <Dialog.Header data-heap-redact-text="true">
        {welcomeMessage}
      </Dialog.Header>
      <Dialog.Body>
        <Paragraph>
          <Trans i18nKey="OnboardingMilestonesStep1.textOne" t={t} />
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="OnboardingMilestonesStep1.textTwo" t={t} />
        </Paragraph>
      </Dialog.Body>
      <Dialog.Footer data-cy={onShowEventName}>
        <Dialog.Button
          variant="secondary"
          onClick={onAdvance}
          data-cy="ds-milestone-clck-continue"
        >
          {t('OnboardingMilestonesStep1.button')}
        </Dialog.Button>
      </Dialog.Footer>
      <div css={decorBox1Style} />
      <div css={decorBox2Style} />
      <div css={decorBox3Style} />
      <div css={decorBox4Style} />
    </>
  );
};

export default Step1;
