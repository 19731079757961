/* eslint-disable filenames/match-exported */
import { Button } from '@datacamp/waffles/button';
import { Tooltip } from '@datacamp/waffles/tooltip';
import React from 'react';
import { withRouter } from 'react-router';

import { navigateTo } from '../helpers/navigation';

function isEventWithModifier(event: any): boolean {
  return Boolean(
    event.metaKey || event.altKey || event.ctrlKey || event.shiftKey,
  );
}

// https://github.com/ReactTraining/react-router/blob/f466c8c4156b6fcdb6baf4fcc723758f7eceeb4b/packages/react-router-dom/modules/Link.js
const LinkButton = React.forwardRef(
  (
    // Prevent some props injected via withRouter to be applied to DOM elements
    // @ts-expect-error type
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { children, disabled, history, href, location, match, onClick, staticContext, target, tooltipText, type, ...restProps }, // prettier-ignore
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    if (type != null) {
      throw new Error("the LinkButton button type can't be set");
    }

    const onClickHooked = (event: any): void => {
      if (onClick) {
        try {
          onClick(event);
          if (event.defaultPrevented) {
            return;
          }
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }
      }

      const isAbsoluteUrl = href.startsWith('http') || href.startsWith('//');
      if (isAbsoluteUrl) {
        return;
      }

      const openInThisTab = !target || target === '_self';
      const isLeftMouseClick = event.button === 0;

      if (isLeftMouseClick && openInThisTab && !isEventWithModifier(event)) {
        event.preventDefault();
        navigateTo(history, href);
      }
    };

    if (disabled) {
      return (
        <Button {...restProps} disabled>
          {children}
        </Button>
      );
    }

    const element = (
      <Button
        {...restProps}
        ref={ref}
        as="a"
        href={href}
        onClick={onClickHooked}
      >
        {children}
      </Button>
    );

    return tooltipText ? (
      <Tooltip content={tooltipText}>{element}</Tooltip>
    ) : (
      element
    );
  },
);

// @ts-expect-error fix later, leftover from javascript
export default withRouter(LinkButton);
