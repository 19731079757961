import { connect } from 'react-redux';

import * as selectors from '../../../../redux/selectors';

import BulletInstructionHeader from './BulletInstructionHeader';

const mapStateToProps = (state: any) => {
  const exercise = selectors.selectExercise(state);
  const currentSubExercise = selectors.selectCurrentSubExercise(state);

  return {
    currentSubExercise,
    subExerciseCount: exercise.get('subexercises').count(),
  };
};

export default connect(mapStateToProps)(BulletInstructionHeader);
