/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Card } from '@datacamp/waffles/card';
import { Heading } from '@datacamp/waffles/heading';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from '../../interfaces/State';
import { showModal } from '../../redux/actions';
import { MODAL_TYPE } from '../../redux/selectors';

const arrowStyle = {
  width: 14,
  height: 14,
  backgroundColor: tokens.colors.white,
  position: 'absolute',
  top: 18,
  right: 9,
  transform: 'rotate(45deg)',
  borderTopLeftRadius: tokens.borderRadius.medium,
  zIndex: tokens.zIndex.default,
  borderTop: `${tokens.borderWidth.thin} solid ${tokens.colors.transparentGrey}`,
  borderLeft: `${tokens.borderWidth.thin} solid ${tokens.colors.transparentGrey}`,
} as const;

const cardStyle = {
  position: 'absolute',
  top: 25,
  right: -14,
  width: 284,
  boxShadow: tokens.boxShadow.xthick,
  paddingLeft: 20,
} as const;

const decorStyle = {
  position: 'absolute',
  top: -1,
  left: -1,
  bottom: -1,
  width: tokens.borderWidth.xthick,
  backgroundColor: tokens.colors.blue,
  borderTopLeftRadius: tokens.borderRadius.medium,
  borderBottomLeftRadius: tokens.borderRadius.medium,
} as const;

const buttonGroupStyle = {
  display: 'flex',
  gap: tokens.spacing.small,
  justifyContent: 'flex-end',
};

export const NotesPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getStarted = useCallback(() => {
    dispatch(showModal({ modal: MODAL_TYPE.COURSE_NOTES }));
    onClose();
  }, [onClose, dispatch]);

  return (
    <div css={{ position: 'relative' }}>
      <div css={arrowStyle} />
      <Card disableHoverEffect css={cardStyle}>
        <div css={decorStyle} />
        <Heading size="large">{t('NotesPopup.header')}</Heading>
        <Paragraph css={{ marginBottom: tokens.spacing.medium }}>
          {t('NotesPopup.datalabText')}
        </Paragraph>
        <div css={buttonGroupStyle}>
          <Button
            variant="plain"
            data-cy="dismiss-notes-popup-button"
            onClick={onClose}
          >
            {t('NotesPopup.secondaryButton')}
          </Button>
          <Button
            variant="secondary"
            data-cy="get-started-notes-popup-button"
            onClick={getStarted}
          >
            {t('NotesPopup.primaryButton')}
          </Button>
        </div>
      </Card>
    </div>
  );
};
