import { tokens } from '@datacamp/waffles/tokens';
import React, { Suspense, useCallback, useMemo, useState } from 'react';

import DCSpinner from '../DCSpinner';
import Reconnecter from '../Reconnecter';

import { XtermProps } from './Xterm';

const XtermWrapper: React.FC<XtermProps> = (props) => {
  const [consoleKey, setConsoleKey] = useState(0);

  const Xterm = useMemo(
    () => React.lazy(() => import(/* webpackChunkName: "xterm" */ './Xterm')),
    [],
  );

  const onReconnect = useCallback(() => {
    setConsoleKey(consoleKey + 1);
  }, [consoleKey]);

  return (
    <div style={{ height: '100%', backgroundColor: tokens.colors.navy }}>
      <Suspense fallback={<DCSpinner uiTheme="DARK" />}>
        <Xterm key={consoleKey} {...props} />
      </Suspense>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'onClicked' does not exist on type '(Intr... Remove this comment to see the full error message */}
      <Reconnecter onClicked={onReconnect} />
    </div>
  );
};

export default XtermWrapper;
