/** @jsx jsx */
import WhatsNext from '@datacamp/learn-whats-next-ui';
import { jsx } from '@emotion/react';
import raven from 'raven-js';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../config';
import { getTreatment } from '../helpers/featureFlags';
import { sendTagManagerEvent } from '../helpers/sendTagManagerEvent';
import { getUrlWithLanguage } from '../i18n';

type Props = {
  courseId?: number;
  courseSlug?: string;
  isCourseEndReached?: boolean;
  meta?: Record<string, unknown>;
  show?: boolean;
  type?: string;
  url?: string;
};

function Screen(props: Props) {
  const { i18n } = useTranslation();

  const shouldShow = props.show && props.type === 'screen' && props.url;

  const treatment = useMemo(() => {
    if (
      !shouldShow ||
      !props.isCourseEndReached ||
      // skip for current user active track
      props.meta?.is_active_track
    ) {
      // control is equivalent to excluded for getTreatment function in campus
      return 'control';
    }

    return getTreatment('wl--end-of-content-exp');
  }, [shouldShow, props.isCourseEndReached, props.meta]);

  useEffect(() => {
    if (treatment && treatment !== 'control') {
      sendTagManagerEvent({
        bucket: treatment,
        event: 'bucket_experiment',
        experimentName: 'wl--end-of-content-exp',
      });
    }
  }, [treatment]);

  const courseUrl = useMemo(() => {
    const datacampUrl = getUrlWithLanguage(config.urls.datacamp, i18n.language);

    return `${datacampUrl}/courses/${props.courseSlug}`;
  }, [i18n.language, props.courseSlug]);

  if (!shouldShow) {
    return null;
  }

  if (treatment === 'on') {
    return (
      <div
        css={{
          zIndex: 999,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <WhatsNext
          baseUrl={config.urls.learnWhatsNextApi}
          contentKey={`${props.courseId}`}
          contentType="course"
          dashboardUrl={config.urls.learn}
          language={i18n.language}
          onError={(error?: Error) => {
            if (error) {
              raven.captureException(error);
            }

            window.location.href = courseUrl;
          }}
        />
      </div>
    );
  }

  return (
    <div className="screen-overlay">
      <iframe
        title="Screen iframe"
        src={props.url}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default Screen;
