import { connect } from 'react-redux';

import * as actions from '../../../../redux/actions';
import * as selectors from '../../../../redux/selectors';

import TabInstructionHeader from './TabInstructionHeader';

const mapStateToProps = (state: any) => {
  const exercise = selectors.selectExercise(state);
  const currentSubExercise = selectors.selectCurrentSubExercise(state);

  return {
    currentSubExercise,
    subExerciseCount: exercise.get('subexercises').count(),
    subExerciseNumber: currentSubExercise.get('number'),
    xpToWin: currentSubExercise.get('xpToWin'),
    currentExercise: exercise,
    isCodeExecuting: selectors.isSessionBusy(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateActiveInstructionTab: (activeKey: any) =>
    dispatch(actions.updateActiveInstructionTab({ activeKey })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabInstructionHeader);
