import { Button } from '@datacamp/waffles/button';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import React, { useCallback, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';

import { I18N_PLACEHOLDER } from '../../../i18n';
import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';

const ExerciseCompletedContinueButton: React.FC<{
  onNext: () => void;
}> = (props) => {
  // Selectors
  const isNavigatingToNextExercise = useSelector(
    selectors.selectIsNavigatingToNextExercise,
  );

  const exerciseWindow = useSelector(selectors.selectChapterWindow).toJS();

  // Callbacks
  const onContinueClick = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onNext();
    },
    [props],
  );

  // Side effects
  const continueButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const continueButton = continueButtonRef.current;
    if (continueButton != null) {
      // Make sure the focus after other Components got the focus
      setImmediate(() => continueButton.focus());
    }
  }, []);

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <Trans
        i18nKey="Assignment.ExerciseCompleted.continueButton"
        components={{
          text: (
            <Text
              css={{
                color: tokens.colors.white,
                fontSize: tokens.fontSizes.large,
                fontWeight: tokens.fontWeights.bold,
                letterSpacing: tokens.letterSpacing.relaxed,
                textTransform: 'uppercase',
                marginBottom: tokens.spacing.small,
              }}
            >
              {I18N_PLACEHOLDER}
            </Text>
          ),
          btn: (
            <Button
              ref={continueButtonRef}
              data-cy="next-exercise-button"
              variant="primary"
              onClick={onContinueClick}
              isLoading={isNavigatingToNextExercise}
              disabled={
                exerciseWindow.type === 'fetching' || exerciseWindow.show
              }
              inverted
            >
              {I18N_PLACEHOLDER}
            </Button>
          ),
        }}
      />
    </div>
  );
};

export default ExerciseCompletedContinueButton;
