/* eslint-disable filenames/match-exported */
/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import NormalInstructions from '../../../containers/Assignment/Instructions/NormalInstructions';
import { canSubmitExercise } from '../../../helpers/examUtilities';
import { navigateTo } from '../../../helpers/navigation';

type Props = {
  history: string;
  nbAttempts: number;
  nextPath: string;
};

const ExamInstructions: React.FC<Props> = ({
  history,
  nbAttempts,
  nextPath,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <NormalInstructions />
      {canSubmitExercise(nbAttempts) === true && (
        <Tooltip content={t('ExamInstructions.tooltip')} placement="bottomLeft">
          <Button
            variant="primary"
            onClick={() => navigateTo(history, nextPath)}
            css={{ marginLeft: tokens.spacing.medium }}
          >
            {t('ExamInstructions.button')}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

// @ts-expect-error fix later, leftover from when codebase was in js
export default withRouter(ExamInstructions);
