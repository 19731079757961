import React, { useCallback } from 'react';

import { useDispatch, useSelector } from '../../interfaces/State';
import { hideDailyStreakScreen } from '../../redux/actions';
import { selectStreakScreen } from '../../redux/selectors';

import DailyStreakScreen from './DailyStreakScreen';

const DailyStreakScreenHandler: React.FC = () => {
  const dispatch = useDispatch();

  const { show } = useSelector(selectStreakScreen);

  const onExit = useCallback(() => {
    dispatch(hideDailyStreakScreen());
  }, [dispatch]);

  if (!show) {
    return null;
  }

  return <DailyStreakScreen onExit={onExit} />;
};

export default DailyStreakScreenHandler;
