import map from 'lodash/map';
import result from 'lodash/result';
import React from 'react';

import { isTabOrBulletConsoleEx } from '../../../../helpers/ComposedConsoleExercise';
import TabPane from '../../../TabPane';
import Tabs from '../../../Tabs';
import MultipleChoiceInstructions from '../MultipleChoiceInstructions';
import Instruction from '../NormalInstructions';

type Props = {
  currentSubExercise?: {
    id: number;
    number: number;
  };
  exercise?: {
    id: number;
    language: string;
    pre_exercise_code?: string;
    subexercises?: Array<{
      question: string;
      type: string;
    }>;
    type?: string;
  };
  onSelectChoice: (...args: any[]) => any;
  onStartSession: (...args: any[]) => any;
  onSubmitChoice: (...args: any[]) => any;
  sessionIsBusy: boolean;
  setMceChoicesFocus: (...args: any[]) => any;
  updateActiveInstructionTab: (...args: any[]) => any;
};

export default class TabInstructions extends React.PureComponent<Props> {
  componentDidMount() {
    this.startSession();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.props.currentSubExercise.id !== prevProps.currentSubExercise.id &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.props.currentSubExercise.id
    ) {
      const override =
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        isTabOrBulletConsoleEx(this.props.exercise.type) ||
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        this.props.exercise.language === 'r'
          ? { command: 'soft_init' }
          : {};
      this.startSession(override);
    }
  }

  startSession(override = {}) {
    this.props.onStartSession({
      ...this.props.exercise,
      ...this.props.currentSubExercise,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      pec: this.props.exercise.pre_exercise_code,
      ...override,
    });
  }

  getInstruction(type: any) {
    switch (type) {
      case 'NormalExercise':
      case 'ShinyExercise':
        return Instruction;
      case 'MultipleChoiceExercise':
        return MultipleChoiceInstructions;
      default:
        return Instruction;
    }
  }

  renderTabs() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ id: number... Remove this comment to see the full error message
      exercise: { id, language, user },
      onSelectChoice,
      onSubmitChoice,
      setMceChoicesFocus,
    } = this.props;
    return map(this.props.exercise?.subexercises, (subEx, key) => {
      const { type } = subEx;
      const SubExInstruction = this.getInstruction(type);

      return (
        // @ts-expect-error ts-migrate(2786) FIXME: Type 'false' is not assignable to type 'Element | ... Remove this comment to see the full error message
        <TabPane unmountDeactivated extraClass key={key}>
          <SubExInstruction
            exerciseProgress={{
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'last_attempt' does not exist on type '{}... Remove this comment to see the full error message
              last_attempt: subEx.last_attempt,
            }}
            exercise={{
              id,
              ...subEx,
              language,
              // @ts-expect-error ts-migrate(2322) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
              user: subEx,
            }}
            optionsContext={subEx.question}
            onSubmitChoice={onSubmitChoice}
            isCompleted={result(user, 'completed.show')}
            onSelectChoice={onSelectChoice(key)}
            setMceChoicesFocus={setMceChoicesFocus}
            key={`${id}_${key}`}
          />
        </TabPane>
      );
    });
  }

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'number' does not exist on type '{ id: nu... Remove this comment to see the full error message
      currentSubExercise: { number },
      updateActiveInstructionTab,
    } = this.props;
    return (
      <div className="instructions--tab">
        <Tabs
          activeKey={String(number - 1)}
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'onTabClick' does not exist on type 'Intr... Remove this comment to see the full error message
          onTabClick={({ toKey }: any) =>
            updateActiveInstructionTab({ activeKey: toKey })
          }
          customComponentClass="instructions--tab-body"
          customTabClass="content--tab mono instructions--tab__title"
        >
          {this.renderTabs()}
        </Tabs>
      </div>
    );
  }
}
