/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { Cross } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardShortcuts from '../KeyboardShortcuts';

type Props = {
  onClose: (...args: any[]) => any;
  onShowShortcutsModal: (...args: any[]) => any;
  shortcutsUsage: number;
  shouldCloseShortcutFrame?: boolean;
};

const Shortcuts: React.FC<Props> = ({
  onClose,
  onShowShortcutsModal,
  shortcutsUsage,
  shouldCloseShortcutFrame,
}) => {
  const { t } = useTranslation();

  if (shouldCloseShortcutFrame || shortcutsUsage) {
    return (
      <div
        css={{
          position: 'absolute',
          bottom: tokens.spacing.large,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="plain" onClick={onShowShortcutsModal} inverted>
          {t('Shortcuts.button')}
        </Button>
      </div>
    );
  }
  return (
    <div
      css={{
        alignSelf: 'stretch',
        flexShrink: 0,
        padding: tokens.spacing.large,
        position: 'relative',
        background: tokens.colors.navyDark,
        textAlign: 'center',
      }}
    >
      <Button
        variant="plain"
        icon={<Cross />}
        aria-label="Close"
        onClick={onClose}
        inverted
        css={{
          color: tokens.colors.white,
          position: 'absolute',
          top: tokens.spacing.small,
          right: tokens.spacing.small,
        }}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: tokens.spacing.medium,
        }}
      >
        <Heading size="large" inverted>
          {t('Shortcuts.heading')}
        </Heading>
        <Text as="p" css={{ color: tokens.colors.white }}>
          {t('Shortcuts.text')}
        </Text>
        <KeyboardShortcuts keys={['Ctrl', 'Shift', 'Enter']} inverted />
        <Button variant="plain" onClick={onShowShortcutsModal} inverted>
          {t('Shortcuts.button')}
        </Button>
      </div>
    </div>
  );
};

export default Shortcuts;
