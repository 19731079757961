import get from 'lodash/get';
import { connect } from 'react-redux';

import * as actions from '../../../redux/actions';
import * as selectors from '../../../redux/selectors';

import ExerciseAside from './ExerciseAside';

const mapStateToProps = (state: any) => {
  const chapterId = selectors.selectChapterId(state);
  const exercise = selectors.selectExercise(state).toJS();
  const user = selectors.selectUserExercise(state).toJS();
  const isCompleted = get(user, 'completed.show', false);
  const isClosed = selectors.selectSidePanelClosedState(state);
  const uiTheme = state.getIn(['settings', 'uiTheme']);

  return {
    chapterId,
    ...exercise,
    isClosed,
    isCompleted,
    xp: get(user, 'currentXp', exercise.xp),
    uiTheme,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  startSession(exercise: any) {
    dispatch(actions.epicStartSession(exercise));
  },

  setClosed(closed: any) {
    dispatch(actions.setSidePanelClosedState(closed));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseAside);
