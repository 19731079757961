import React, { useEffect } from 'react';

import config from '../../config';

import createRunExamplesListener from './runExamplesListener';

type Props = {
  path: string;
  sendCode: (code: string) => void;
};

const RDoc: React.FC<Props> = ({ path, sendCode }) => {
  useEffect(() => {
    const listener = createRunExamplesListener({ sendCode });
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [sendCode]);

  if (path == null) {
    return null;
  }

  let computedPath = `${config.urls.rdoc}/campus/help/${path}${
    path.indexOf('?') === -1 ? '?' : '&'
  }`;
  computedPath += `viewer_pane=1&campus_app=1&updateTime=${new Date().getTime()}`;
  return (
    <div className="exercise--documentation">
      <iframe title="R documentation" src={computedPath} />
    </div>
  );
};

export default RDoc;
