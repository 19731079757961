/** @jsx jsx */
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  xpWon: number;
};

const ExerciseCompletedXp: React.FC<Props> = ({ xpWon }) => {
  const { t } = useTranslation();

  return (
    <div>
      <svg
        className="dc-exercise-completed__checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 708 708"
      >
        <path
          className="dc-exercise-completed__checkmark-end"
          fill="currentColor"
          d="m628.07 248.04 41.98-58.31 14.77 38.72-56.75 19.59zM174.39 333.42l36.41-49.92s18.59 22.34 30.59 37.34l-51.59 31.78-15.41-19.2Z"
        />
        <path
          className="dc-exercise-completed__checkmark-main"
          d="m212.85 333.29 121.83 145.78 263.65-337.46-15.5-16.76C524.23 66.25 443.27 30 353.84 30 174.99 30 30 174.99 30 353.84s144.99 323.84 323.84 323.84 323.84-144.99 323.84-323.84c0-41.11-7.66-80.43-21.63-116.61"
          fill="none"
          stroke="white"
          strokeLinejoin="round"
          strokeWidth="60"
        />
      </svg>
      <Text
        as="p"
        css={{
          marginTop: tokens.spacing.medium,
          color: tokens.colors.white,
          textAlign: 'center',
          fontSize: tokens.fontSizes.large,
        }}
      >
        {t('Assignment.ExerciseCompletedXp.text', { xp: xpWon })}
      </Text>
    </div>
  );
};

export default ExerciseCompletedXp;
