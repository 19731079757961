import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import CourseOutline from './CourseOutline';

const mapStateToProps = (state: any) => {
  const course = selectors.selectCourse(state).toJS();
  const currentExercisesProgress = selectors.selectExercisesProgress(state);
  return {
    course,
    courseRef: course.slug || course.id,
    currentChapter: selectors.selectChapter(state).toJS(),
    currentExercises: selectors.selectExercises(state).toJS(),
    currentExerciseId: selectors.selectExercise(state).get('id'),
    currentExercisesProgress: currentExercisesProgress
      ? currentExercisesProgress.toJS()
      : [],
    learningMode: selectors.selectLearningMode(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onViewDetailExercises: (
    isExpanding: any,
    isCurrentChapter: any,
    settings: any,
  ) => {
    if (isExpanding && !isCurrentChapter) {
      dispatch(actions.epicGetExercisesFromChapter(settings));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseOutline);
