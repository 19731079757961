/** @jsx jsx */
import { Avatar } from '@datacamp/waffles/avatar';
import { Checkmark } from '@datacamp/waffles/icon';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx, keyframes } from '@emotion/react';
import { TFunction } from 'i18next';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import DCLink from '../../../../DCLink';

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

type Props = {
  isActive: boolean;
  isCodeExecuting: boolean;
  onClick: (subExerciseNumber: string) => void;
  // import ImmutablePropTypes from 'react-immutable-proptypes';
  subExercise: any;
  t: TFunction;
  // TODO: ImmutablePropTypes.contains({ number: PropTypes.number.isRequired, })
  unlocked: boolean;
};

class ProgressBullet extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onBulletClick = this.onBulletClick.bind(this);
  }

  onBulletClick(event: any) {
    event.preventDefault();
    const { onClick, subExercise, unlocked } = this.props;
    if (unlocked) {
      // TODO: reducers are bad. They should be using the exercise number.
      // In the meantime, it's needed to do this strange mapping.
      onClick(String(subExercise.get('number') - 1));
    }
  }

  render() {
    const { isActive, isCodeExecuting, subExercise, t, unlocked } = this.props;
    const usedHelp = !!(
      subExercise.get('isSolutionShown', false) ||
      subExercise.get('isHintShown', false)
    );
    const isCompleted = !!subExercise.getIn(['completed', 'completed'], false);

    const renderBullet = () => {
      const defaultAvatarVariant = isActive ? 'white' : 'greyLight';

      return (
        <DCLink
          onClick={this.onBulletClick}
          css={{
            opacity: isActive ? 1 : 0.8,
            pointerEvents: unlocked ? 'all' : 'none',
            animation: isCodeExecuting
              ? `${blinkAnimation} 1600ms ease-out infinite`
              : 'none',
          }}
        >
          <Avatar
            variant={isCompleted ? 'green' : defaultAvatarVariant}
            size="xsmall"
            content={isCompleted ? <Checkmark /> : subExercise.get('number')}
          />
        </DCLink>
      );
    };

    return isCompleted && usedHelp ? (
      <Tooltip content={t('ProgressBullet.tooltip')} placement="top">
        {renderBullet()}
      </Tooltip>
    ) : (
      renderBullet()
    );
  }
}

export default withTranslation()(ProgressBullet);
