import { useEffect } from 'react';

import { ContentAuthorizationStatus } from '../../redux/actions/contentAuthorization';
import { ChapterContentAuthorization } from '../../redux/reducers/contentAuthorization';

export type UseContentAuthorizationServiceArgs = {
  casResult: ChapterContentAuthorization | null;
  chapterId: number;
  fetchContentAuthorization: (chapterId: number) => void;
  isUserLoggedIn: boolean;
  showArchivedResourceModal: () => void;
  showPaywallModal: () => void;
};
const useContentAuthorizationService = ({
  casResult,
  chapterId,
  fetchContentAuthorization,
  isUserLoggedIn,
  showArchivedResourceModal,
  showPaywallModal,
}: UseContentAuthorizationServiceArgs): void => {
  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    fetchContentAuthorization(chapterId);
  }, [chapterId, isUserLoggedIn, fetchContentAuthorization]);

  useEffect(() => {
    const isAccessDenied =
      casResult?.status === ContentAuthorizationStatus.denied;
    if (!isAccessDenied) {
      return;
    }

    const isArchivedResource = casResult.denyReason === 'archived-resource';
    if (isArchivedResource) {
      showArchivedResourceModal();
      return;
    }

    showPaywallModal();
  }, [casResult, showArchivedResourceModal, showPaywallModal]);
};

export default useContentAuthorizationService;
