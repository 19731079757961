import { connect } from 'react-redux';

import * as actions from '../../../../redux/actions';
import * as selectors from '../../../../redux/selectors';

import BulletInstructions from './BulletInstructions';

const mapStateToProps = (state: any) => ({
  exercise: selectors.selectExercise(state).toJS(),
  currentSubExercise: selectors.selectCurrentSubExercise(state).toJS(),
  sessionIsBusy: selectors.isSessionBusy(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onStartSession: (settings: any) =>
    dispatch(actions.epicStartSession(settings)),

  updateActiveInstructionTab: ({ activeKey }: any) =>
    dispatch(actions.updateActiveInstructionTab({ activeKey })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulletInstructions);
