import React from 'react';

const isFunction = (fn: any) => typeof fn === 'function';

const hcKeyListener = (onKeyDown: any) => (WrappedComponent: any) =>
  class KeyListener extends React.PureComponent {
    onKeyDownBinded: any;

    reference: any;

    componentDidMount() {
      this.onKeyDownBinded = (e: any) => {
        if (isFunction(onKeyDown)) {
          onKeyDown(e, this.props);
        }
        if (this.reference && isFunction(this.reference.onKeyDown)) {
          this.reference.onKeyDown(e);
        }
      };
      document.addEventListener('keydown', this.onKeyDownBinded);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.onKeyDownBinded);
    }

    render() {
      const propsToInject = {};
      if (WrappedComponent.prototype instanceof React.Component) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'ref' does not exist on type '{}'.
        propsToInject.ref = (reference: any) => {
          this.reference = reference;
        };
      }
      return <WrappedComponent {...this.props} {...propsToInject} />;
    }
  };

export default hcKeyListener;
