import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import { Component } from 'react';

import { SOURCE_TYPES } from './GraphicalOutput';

const HTML_TEMPLATE_BEFORE = `<!doctype html>
<html lanf="en">
  <head>
    <meta charset="utf-8">
    <style>
      html, body, iframe, img, object {
        margin: 0;
        padding: 0;
        border: 0;
        display: block;
        width: 100%;
        height: 100%;
      }
    </style>
  </head>
  <body>`;
const HTML_TEMPLATE_AFTER = ` </body>
</html>`;

type OwnProps = {
  hasFocus?: boolean;
  onBlur?: () => void;
  onClose?: () => void;
  onFocus?: () => void;
  onResize?: (innerWidth: number, innerHeight: number) => void;
  // TODO: PropTypes.oneOf(Object.keys(SOURCE_TYPES))
  sourceData?: string;
  sourceType?: any;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {};
const defaultProps = {
  onClose: noop,
  onFocus: noop,
  onBlur: noop,
  onResize: noop,
  hasFocus: true,
};

type Props = OwnProps & typeof defaultProps;

class ExpandedGraphicalOutput extends Component<Props> {
  static defaultProps = defaultProps;

  eventListeners: any;

  managedWindow: any;

  constructor(props: Props) {
    super(props);
    this.managedWindow = null;
    this.eventListeners = {
      unload: () => {
        this.props.onClose();
      },
      focus: () => {
        this.props.onFocus();
      },
      blur: () => {
        this.props.onBlur();
      },
      resize: debounce(() => {
        this.props.onResize(
          this.managedWindow.innerWidth,
          this.managedWindow.innerHeight,
        );
      }, 500),
    };
  }

  componentDidMount() {
    const height = window.screen.height * 0.8;
    const width = window.screen.width * 0.8;
    this.managedWindow = window.open(
      '',
      '_blank',
      `height=${height}px,width=${width}px`,
    );
    this.refreshContent();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.hasFocus && !prevProps.hasFocus) {
      this.managedWindow.focus();
    }

    if (
      this.props.sourceType !== prevProps.sourceType ||
      this.props.sourceData !== prevProps.sourceData
    ) {
      this.refreshContent();
    }
  }

  refreshContent() {
    if (this.props.sourceData == null) {
      return;
    }
    forEach(this.eventListeners, (listener, eventName) => {
      this.managedWindow.removeEventListener(eventName, listener);
    });
    if (this.props.sourceType === SOURCE_TYPES.img) {
      this.managedWindow.document.open();
      this.managedWindow.document.write(
        `${HTML_TEMPLATE_BEFORE}<img src="${this.props.sourceData}"></img>${HTML_TEMPLATE_AFTER}`,
      );
      this.managedWindow.document.close();
    }
    if (this.props.sourceType === SOURCE_TYPES.iframe) {
      this.managedWindow.document.open();
      this.managedWindow.document.write(
        `${HTML_TEMPLATE_BEFORE}<iframe src="${this.props.sourceData}"></iframe>${HTML_TEMPLATE_AFTER}`,
      );
      this.managedWindow.document.close();
    }
    if (this.props.sourceType === SOURCE_TYPES.rawIframe) {
      this.managedWindow.document.open();
      this.managedWindow.document.write(this.props.sourceData);
      this.managedWindow.document.close();
    }
    if (this.props.sourceType === SOURCE_TYPES.pdf) {
      this.managedWindow.document.open();
      this.managedWindow.document.write(
        `${HTML_TEMPLATE_BEFORE}<object data="${this.props.sourceData}" type="application/pdf" allowFullScreen></object>${HTML_TEMPLATE_AFTER}`,
      );
      this.managedWindow.document.close();
    }
    forEach(this.eventListeners, (listener, eventName) => {
      this.managedWindow.addEventListener(eventName, listener);
    });
  }

  componentWillUnmount() {
    this.managedWindow.close();
  }

  render() {
    return false;
  }
}

export default ExpandedGraphicalOutput;
