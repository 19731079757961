import map from 'lodash/map';

import GraphicalOutputs from '../../containers/GraphicalOutputs';
import { MarkdownEditor } from '../CodeEditor/CodeEditorContainer';

import { getComponent } from './layoutBuilder';
import { defaultSettings, leftPaneWidths } from './layoutsettings';

export default function buildMarkdownLayout(props: any) {
  const {
    exercise,
    setActiveTab,
    user: { inputMarkdownTabs, outputMarkdownTabs },
  } = props;

  const inputStack = map(inputMarkdownTabs, (tab, key) => ({
    key,
    id: `input-${key}`,
    type: 'react-component',
    component: `input-${key}`,
    title: tab.title,
    isClosable: false,
    parentKey: 'inputMarkdownTabs',
    reactComponent: MarkdownEditor(key),
  }));

  const outputMap = {
    html: {
      component: GraphicalOutputs({
        category: 'outputMarkdownTabs',
        key: 'html',
        overwrittenProps: {
          previousButtonTitle: 'Previous HTML',
          nextButtonTitle: 'Next HTML',
        },
      }),
      props: {
        tabKey: 'html',
        tabCategory: 'outputMarkdownTabs',
      },
    },
    pdf: {
      component: GraphicalOutputs({
        category: 'outputMarkdownTabs',
        key: 'pdf',
        overwrittenProps: {
          previousButtonTitle: 'Previous PDF',
          nextButtonTitle: 'Next PDF',
        },
      }),
      props: {
        tabKey: 'pdf',
        tabCategory: 'outputMarkdownTabs',
      },
    },
    slides: {
      component: getComponent(exercise.language)('slides'),
      props: { title: 'Slides', tabCategory: 'outputMarkdownTabs' },
    },
    notes: {
      component: getComponent(exercise.language)('notes'),
      props: { title: 'Notes', tabCategory: 'outputMarkdownTabs' },
    },
  };

  const outputStack = map(outputMarkdownTabs, (tab, key) => ({
    key,
    id: `output-${key}`,
    type: 'react-component',
    component: `output-${key}`,
    title: tab.title,
    parentKey: 'outputMarkdownTabs',
    isClosable: false,
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    reactComponent: outputMap[key].component,
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    props: outputMap[key].props,
  }));

  return {
    ...defaultSettings,
    content: [
      {
        type: 'row',
        id: 'root',
        content: [
          {
            type: 'stack',
            id: 'aside-stack',
            isClosable: false,
            hasHeaders: false,
            width: leftPaneWidths.MarkdownExercise,
            content: [
              {
                type: 'react-component',
                id: 'aside',
                component: 'aside',
                reactComponent: getComponent(exercise.language)('aside'),
                title: 'Instructions',
                isClosable: false,
              },
            ],
          },
          {
            type: 'row',
            height: 100 - leftPaneWidths.MarkdownExercise,
            id: 'content',
            content: [
              {
                type: 'stack',
                id: 'input-stack',
                onActiveItemChanged: (e: any) => {
                  setActiveTab(e.config.parentKey, e.config.key);
                },
                content: inputStack,
              },
              {
                type: 'stack',
                id: 'output-stack',
                onActiveItemChanged: (e: any) => {
                  setActiveTab(e.config.parentKey, e.config.key);
                },
                content: outputStack,
              },
            ],
          },
        ],
      },
    ],
  };
}
