import { Dialog } from '@datacamp/waffles/dialog';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { State } from '../../interfaces/State';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

const stepComponentByStep = [Step1, Step2, Step3, Step4];

type StateProps = {
  firstName?: string;
  isExternalExercise: boolean;
  isOpen: boolean;
  step: number;
  xp?: number;
};

type DispatchProps = {
  advanceOnboardingMilestones: () => Promise<void>;
  nextExternalExercise: () => Promise<void>;
  nextInternalExercise: () => Promise<void>;
  onboardingMilestonesStarted: () => Promise<void>;
};

type OnboardingMilestonesProps = StateProps & DispatchProps;

const OnboardingMilestones: React.FC<OnboardingMilestonesProps> = ({
  advanceOnboardingMilestones,
  firstName,
  isExternalExercise,
  isOpen,
  nextExternalExercise,
  nextInternalExercise,
  onboardingMilestonesStarted,
  step,
  xp,
}) => {
  const StepComponent = get(stepComponentByStep, step, null);

  const onAdvance = useCallback(() => {
    if (step === 0) {
      return advanceOnboardingMilestones();
    }

    if (isExternalExercise) {
      return nextExternalExercise();
    }

    return nextInternalExercise();
  }, [
    step,
    isExternalExercise,
    advanceOnboardingMilestones,
    nextExternalExercise,
    nextInternalExercise,
  ]);

  useEffect(() => {
    if (step === 0 && isOpen) {
      onboardingMilestonesStarted();
    }
  }, [step, isOpen, onboardingMilestonesStarted]);

  return (
    <Dialog
      isOpen={isOpen && !isNull(StepComponent)}
      onClose={noop}
      alignCenter
      closeButtonOverride={<></>}
    >
      {/* Placeholder Dialog.Header to avoid Waffles warning with StepComponent uncertainty */}
      {!isOpen && (
        <Dialog.Header>
          <></>
        </Dialog.Header>
      )}
      {StepComponent && (
        <StepComponent onAdvance={onAdvance} xp={xp} firstName={firstName} />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  isOpen: selectors.selectIsOnboardingMilestonesOpen(state),
  step: selectors.selectOnboardingMilestones(state).step,
  isExternalExercise: selectors.selectIsExternalExercise(state),
  firstName: selectors.selectUserSettings(state).get('first_name'),
  xp: get(selectors.selectGlobalUserProgress(state), 'exercise.xp'),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  nextInternalExercise: () => dispatch(actions.nextInternalExercise()),
  nextExternalExercise: () => dispatch(actions.nextExternalExercise()),

  advanceOnboardingMilestones: () =>
    dispatch(actions.advanceOnboardingMilestones()),
  onboardingMilestonesStarted: () =>
    dispatch(actions.onboardingMilestonesStarted()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnboardingMilestones);
