/** @jsx jsx */
import { constants as rdeExerciseConstants } from '@datacamp/remote-desktop-exercise-core';
import { Button } from '@datacamp/waffles/button';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from '../../interfaces/State';
import { showModal } from '../../redux/actions';
import { MODAL_TYPE } from '../../redux/selectors';

const buttonStyle = {
  marginRight: tokens.spacing.xsmall,
};

type Props = {
  exerciseType?: string;
};

const GuidesHeader: React.FC<Props> = ({ exerciseType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClick = useCallback(
    () => dispatch(showModal({ modal: MODAL_TYPE.GUIDES })),
    [dispatch],
  );

  if (exerciseType !== rdeExerciseConstants.exercises.REMOTE_DESKTOP_EXERCISE) {
    return null;
  }

  return (
    <Button
      data-cy="guides-button"
      onClick={onClick}
      variant="plain"
      css={buttonStyle}
    >
      {t('GuidesHeader.button')}
    </Button>
  );
};

export default GuidesHeader;
