/** @jsx jsx */
import { Checkmark } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { jsx } from '@emotion/react';
import React from 'react';

type Props = {
  exerciseTotalXp: number;
  exerciseWasCompleted: boolean;
  xpWon: number;
};

const ChapterExerciseXp: React.FC<Props> = ({
  exerciseTotalXp,
  exerciseWasCompleted,
  xpWon,
}) => {
  const gotAllXp = xpWon === exerciseTotalXp;

  let xpShown;

  if (exerciseWasCompleted) {
    if (gotAllXp) {
      xpShown = (
        <>
          <Checkmark />
          {exerciseTotalXp}
        </>
      );
    } else {
      xpShown = xpWon;
    }
  } else {
    xpShown = exerciseTotalXp;
  }

  return (
    <Text
      css={{
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {xpShown} XP
    </Text>
  );
};

export default ChapterExerciseXp;
