import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../config';

import TrackingGenerator from './TrackingGenerator';
import { useOpenLinksInParentWindow } from './useOpenLinksInParentWindow';

type Props = {
  canonical?: string;
  description?: string;
  isSingleExerciseMode: boolean;
  shouldBeIndexed?: boolean;
  title: string;
};

const HeadManager: React.FC<Props> = ({
  canonical,
  description,
  isSingleExerciseMode,
  shouldBeIndexed = true,
  title,
}) => {
  useOpenLinksInParentWindow(isSingleExerciseMode);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {canonical && <link rel="canonical" href={canonical} />}
        {!shouldBeIndexed && <meta name="robots" content="noindex" />}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="fragment" content="!" />
        <meta
          name="keywords"
          content="R, Python, Data analysis, interactive, learning"
        />
        <meta
          name="description"
          content={
            description ||
            'DataCamp is a free online interactive learning platform for data analytics. Think of it as Codecademy, but for data science.'
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@DataCamp" />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={
            description ||
            'DataCamp is a free online interactive learning platform for data analytics. Think of it as Codecademy, but for data science.'
          }
        />
        <meta name="twitter:creator" content="@DataCamp" />
        <meta
          name="twitter:image:src"
          content="/public/assets/images/var/twitter_share.png"
        />
        <meta name="twitter:domain" content="www.datacamp.com" />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content="/public/assets/images/var/linkedin_share.png"
        />
        <meta
          name="google-signin-clientid"
          content="892114885437-01a7plbsu1b2vobuhvnckmmanhb58h3a.apps.googleusercontent.com"
        />
        <meta name="google-signin-scope" content="email profile" />
        <meta name="google-signin-cookiepolicy" content="single_host_origin" />

        <link
          rel="preload"
          href="https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-english-v2.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-english.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-latin-v2.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-english.woff2"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-rest.woff2"
          as="font"
          crossOrigin="anonymous"
        />

        <link rel="preconnect" href={config.urls.campusApi} />
        <link rel="dns-prefetch" href={config.urls.campusApi} />

        <link rel="preconnect" href={config.urls.projector} />
        <link rel="dns-prefetch" href={config.urls.projector} />

        <link rel="preconnect" href={config.urls.assets} />
        <link rel="dns-prefetch" href={config.urls.assets} />
      </Helmet>
      <TrackingGenerator />
    </>
  );
};

export default HeadManager;
