/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Mobile } from '@datacamp/waffles/icon';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { headerIconButton } from '../../styles/campus/buttonOverrides';

type Props = {
  onClick: (...args: any[]) => any;
};

const MobileButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={t('MobilePopup.MobileButton.label')}
      placement="bottomRight"
    >
      <Button
        data-cy="header-mobile"
        onClick={onClick}
        aria-label={t('MobilePopup.MobileButton.label')}
        css={headerIconButton}
        icon={<Mobile />}
        variant="plain"
      />
    </Tooltip>
  );
};

export default MobileButton;
