/** @jsx jsx */
import { Avatar } from '@datacamp/waffles/avatar';
import { Checkmark } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { Tooltip } from '@datacamp/waffles/tooltip';
import { jsx, keyframes } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DCLink from '../../../../DCLink';

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

type Props = {
  active?: boolean;
  CustomTag?: string;
  index?: number;
  isCodeExecuting?: boolean;
  isCompleted?: boolean;
  onClick?: (...args: any[]) => any;
  subExercise?: unknown;
  unlocked?: boolean;
};

const TabOfSubExercises: React.FC<Props> = ({
  active,
  index,
  isCodeExecuting,
  isCompleted,
  onClick,
  subExercise,
  unlocked,
}) => {
  const { t } = useTranslation();

  const onClickHooked = (e: any) => {
    e.preventDefault();
    if (unlocked) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick();
    }
  };
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const usedHelp = subExercise.isSolutionShown || subExercise.isHintShown;

  const renderBullet = () => {
    return (
      <DCLink
        onClick={onClickHooked}
        css={{
          opacity: 1,
          pointerEvents: unlocked ? 'all' : 'none',
          animation: isCodeExecuting
            ? `${blinkAnimation} 1600ms ease-out infinite`
            : 'none',
          outline: 0,
          borderRadius: tokens.borderRadius.circle,
          ':focus-visible': {
            boxShadow: `0 0 0 2px ${tokens.colors.blueDark}`,
          },
        }}
      >
        <Avatar
          variant={active ? 'navy' : 'grey'}
          size="xsmall"
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          content={isCompleted ? <Checkmark /> : `${index + 1}`}
        />
      </DCLink>
    );
  };

  return isCompleted && !usedHelp ? (
    <Tooltip content={t('TabOfSubExercises.tooltip')} placement="right">
      {renderBullet()}
    </Tooltip>
  ) : (
    renderBullet()
  );
};

export default TabOfSubExercises;
