/** @jsx jsx */
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const AssignmentText: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <Text
      {...restProps}
      as="div"
      css={{
        lineHeight: tokens.lineHeights.relaxed,
        '& code': {
          fontFamily: tokens.fontFamilies.mono,
          margin: '0 2px',
          padding: '2px 4px',
          lineHeight: tokens.lineHeights.default,
          backgroundColor: tokens.colors.greyLight,
          borderRadius: tokens.borderRadius.medium,
          fontSize: '86%',
          mixBlendMode: 'multiply',
        },
        '& pre': {
          backgroundColor: tokens.colors.greyLight,
          padding: tokens.spacing.small,
          margin: 0,
          borderRadius: tokens.borderRadius.medium,
          tabSize: 4,
          whiteSpace: 'pre',
          lineHeight: tokens.lineHeights.default,
          mixBlendMode: 'multiply',
        },
        '& pre > code': {
          margin: 0,
          padding: 0,
          backgroundColor: 'transparent',
        },
        '& ul, & ol': {
          paddingLeft: tokens.spacing.medium,
          '&:first-of-type': {
            marginTop: 0,
          },
        },
        '& p': {
          '&:first-of-type': {
            marginTop: 0,
          },
        },
        '& li': {
          marginBottom: tokens.spacing.small,
        },
        '& a': {
          color: tokens.colors.blueDarkText,
          textDecoration: 'none',
          fontWeight: tokens.fontWeights.bold,
          borderRadius: tokens.borderRadius.medium,
          ':hover': {
            color: tokens.colors.blueDarkText,
            textDecoration: 'underline',
          },
          ':focus-visible': {
            boxShadow: `0 0 0 2px ${tokens.colors.blueDark}`,
          },
          outline: 0,
        },
        '& a code': {
          color: tokens.colors.blueDarkText,
        },
        '& hr': {
          backgroundColor: tokens.colors.transparentGrey,
          border: 0,
          height: 1,
          margin: `${tokens.spacing.medium} 0`,
        },
      }}
    >
      {children}
    </Text>
  );
};

export default AssignmentText;
