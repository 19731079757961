/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Card } from '@datacamp/waffles/card';
import { Heading } from '@datacamp/waffles/heading';
import { Cross } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx, keyframes } from '@emotion/react';
import join from 'lodash/join';
import map from 'lodash/map';
import trim from 'lodash/trim';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { Technology } from '../../helpers/technology';

const url = (...paths: string[]) =>
  join(
    map(paths, (path) => trim(encodeURI(path), '/')),
    '/',
  );

const techGradient = (technology?: Technology) => {
  switch (technology) {
    case 'Tableau':
      return tokens.colors.pink;
    case 'Power BI':
      return tokens.colors.blueLight;
    case 'Python':
    case 'Google Sheets':
      return tokens.colors.greyMedium;
    case 'Scala':
      return tokens.colors.redDark;
    case 'Theory':
      return tokens.colors.blueLight;
    default:
      return tokens.colors.blueDark;
  }
};

const teachTechnology: Technology[] = ['Python', 'R', 'SQL'];

export type ISearchLandingPopUpProps = {
  contentLink: string;
  delay: number;
  popupSoundLink?: string;
  technology?: Technology;
  title: string;
};

const fadeIn = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.5);
  }
  70% {
    opacity: 1;
    transform: scale(1.2);
  }
  95% {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

export const SearchLandingPopUp: React.FC<ISearchLandingPopUpProps> = ({
  contentLink,
  delay,
  popupSoundLink,
  technology,
  title,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const openModal = React.useCallback(() => setIsOpen(true), []);
  const closeModal = React.useCallback(() => setIsOpen(false), []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      openModal();
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, openModal]);

  const technologyLink = React.useMemo(() => {
    const domain = config.urls.datacamp;
    if (technology && teachTechnology.includes(technology)) {
      return url(domain, 'learn', technology.toLowerCase());
    }
    // no learn link for Scala, Google Sheets, Theory, Git, Tableau, ...
    return url(domain, 'courses', `tech:${technology}`);
  }, [technology]);

  React.useEffect(() => {
    if (isOpen && popupSoundLink !== undefined) {
      const audio = new Audio(popupSoundLink);
      audio.play().catch((e) => {
        // we can ignore this, the user will not hear the sound play but that's not really a problem
        // the reason that this exception gets thrown is that the user didn't interact with the page yet
        // eslint-disable-next-line no-console
        console.log(e);
      });
    }
  }, [isOpen, popupSoundLink]);

  if (!isOpen) {
    return null;
  }

  return (
    <Card
      disableHoverEffect
      css={{
        animation: `${fadeIn} 300ms ease-in 1`,
        overflow: 'hidden',
        position: 'fixed',
        padding: tokens.spacing.large,
        paddingBottom: tokens.spacing.medium,
        right: tokens.spacing.xlarge,
        bottom: tokens.spacing.xlarge,
        width: 380,
        zIndex: 100,
        boxShadow: tokens.boxShadow.xthick,
      }}
    >
      <div
        css={{
          background: techGradient(technology),
          height: 4,
          left: 0,
          top: 0,
          position: 'absolute',
          width: '100%',
        }}
      ></div>
      <Button
        aria-label="Close"
        size="small"
        variant="plain"
        css={{
          color: tokens.colors.navy,
          position: 'absolute',
          right: 12,
          top: 16,
        }}
        data-cy="close-search-landing-popup"
        onClick={closeModal}
        icon={<Cross />}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: tokens.spacing.small,
        }}
      >
        <Heading size="large" css={{ marginBottom: 0 }}>
          {t('SearchLandingPopup.heading')}
        </Heading>
        <Heading size="xlarge">{title}</Heading>
        <Button
          as="a"
          variant="primary"
          data-cy="click-content-link-search-landing-popup"
          href={contentLink}
        >
          {t('SearchLandingPopup.primaryButton')}
        </Button>
        <Button
          as="a"
          variant="plain"
          data-cy="click-technology-link-search-landing-popup"
          href={technologyLink}
        >
          {t('SearchLandingPopup.secondaryButton', { technology })}
        </Button>
      </div>
    </Card>
  );
};
