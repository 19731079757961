/** @jsx jsx */
import { ErrorBoundary } from '@datacamp/le-shared-components';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import raven from 'raven-js';
import React, { Suspense } from 'react';

import DCSpinner from '../DCSpinner';

import type { MonacoConsoleProps } from './MonacoConsole';

const consoleImporter = () =>
  import(/* webpackChunkName: "console-monaco" */ './MonacoConsole');

const MonacoConsole = React.lazy(consoleImporter);

type Props = MonacoConsoleProps & {
  id: number | string;
};
const ConsoleLoader: React.FC<Props> = (props) => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        ...(props.uiTheme === 'DARK' && {
          backgroundColor: tokens.colors.navy,
        }),
      }}
    >
      <ErrorBoundary onErrorReported={raven.captureException}>
        <Suspense fallback={<DCSpinner uiTheme={props.uiTheme} />}>
          <MonacoConsole {...props} key={props.id} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default ConsoleLoader;
