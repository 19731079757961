/** @jsx jsx */
import { Badge } from '@datacamp/waffles/badge';
import { Heading } from '@datacamp/waffles/heading';
import { CheckmarkCircle } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  // import ImmutablePropTypes from 'react-immutable-proptypes';
  currentSubExercise?: any; // TODO: ImmutablePropTypes.contains({ number: PropTypes.number.isRequired, xpToWin: PropTypes.number.isRequired, })
  subExerciseCount?: number;
};

const BulletInstructionHeader: React.FC<Props> = ({
  currentSubExercise,
  subExerciseCount,
}) => {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingRight: tokens.spacing.medium,
      }}
    >
      <Heading
        size="xlarge"
        css={{
          fontSize: tokens.fontSizes.large,
          margin: 0,
          display: 'flex',
          gap: tokens.spacing.small,
          alignItems: 'center',
          paddingLeft: tokens.spacing.medium,
          width: '100%',
          flexGrow: 1,
        }}
      >
        <CheckmarkCircle size="small" />
        {t('BulletInstructionHeader.heading', {
          number: currentSubExercise.get('number'),
          total: subExerciseCount,
        })}
      </Heading>
      <Badge variant="yellow">{`${currentSubExercise.get(
        'xpToWin',
      )} XP`}</Badge>
    </div>
  );
};

export default BulletInstructionHeader;
