import React from 'react';

import ExerciseAside from '../Exercise/ExerciseAside';
import ExerciseWaiting from '../Exercise/ExerciseWaiting';
import { leftPaneWidths } from '../Layouts/layoutsettings';

type Props = {
  exercise?: {};
  solution?: string;
};

const NormalExerciseServerLayout: React.FC<Props> = ({
  exercise,
  solution,
}) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const leftPaneWidth = leftPaneWidths[exercise.type] || leftPaneWidths.default;
  return (
    <div data-cy="server-side-loader-placeholder">
      <ExerciseAside
        {...{ ...exercise, sidebarStyle: { width: `${leftPaneWidth}%` } }}
      />
      <section
        className="exercise--content"
        style={{ width: `${100 - leftPaneWidth}%` }}
      >
        <ExerciseWaiting {...{ code: solution }} />
      </section>
    </div>
  );
};

export default NormalExerciseServerLayout;
