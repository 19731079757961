/** @jsx jsx */
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

import ProgressBullet from './ProgressBullet';

type Props = {
  currentExercise: unknown;
  currentSubExercise: unknown;
  isCodeExecuting: boolean;
  onBulletClick: (subExerciseNumber: string) => void;
};

/**
 * This component display a serie of bullet links that contain
 * either the number of the corresponding exercise
 * or a check icon to indicate completion.
 * TODO: make it a generic reusable component.
 */
const ProgressBullets: React.FC<Props> = (props) => {
  const { currentExercise, isCodeExecuting, onBulletClick } = props;

  return (
    <ul
      css={{
        paddingLeft: tokens.spacing.small,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'get' does not exist on type '{}'.
        currentExercise
          .get('subexercises')
          .map((subExercise: any, index: any, list: any) => {
            const unlocked =
              subExercise.get('unlocked', false) ||
              subExercise.get('number') === 1;
            const isActive = subExercise === props.currentSubExercise;

            return (
              <>
                <ProgressBullet
                  key={index}
                  onClick={onBulletClick}
                  unlocked={unlocked}
                  isCodeExecuting={isCodeExecuting}
                  subExercise={subExercise}
                  isActive={isActive}
                />
                {/* Line connecting multiple bullets */}
                {list.size > 1 && index < list.size - 1 && (
                  <div
                    css={{
                      height: 2,
                      width: 8,
                      backgroundColor: isActive
                        ? tokens.colors.white
                        : tokens.colors.greyLight,
                    }}
                  />
                )}
              </>
            );
          })
      }
    </ul>
  );
};

export default ProgressBullets;
