import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import EditorToggle from './EditorToggle';

const mapStateToProps = (state: any) => {
  return {
    mobileView: selectors.selectMobileView(state),
    exerciseType: selectors.selectExercise(state).get('type'),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setMobileView: (selectedView: 'CONTEXT' | 'EXERCISE') => {
    dispatch(actions.setMobileView(selectedView));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorToggle);
