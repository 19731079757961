import { Rating } from '@datacamp/le-shared-components';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ExerciseCompletedRateContent: React.FC<{
  onSubmit: (ratingValue: any) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      data-cy="exercise-rating"
      css={{ marginBottom: tokens.spacing.large, color: tokens.colors.white }}
    >
      <div>
        <Text
          as="p"
          css={{
            marginBottom: tokens.spacing.small,
            color: tokens.colors.white,
          }}
        >
          {t('Assignment.ExerciseCompleted.RateContent.text')}
        </Text>
      </div>
      <Rating onRate={props.onSubmit} />
    </div>
  );
};

export default ExerciseCompletedRateContent;
