// We can not import normally from campus-integration as loading the entire lib causes SSR crash.
// The type is imported in react-app-env.d.ts
import { connect } from 'react-redux';

import {
  getUrlParamsFromVideoExercise,
  getVideoUrl,
} from '../../helpers/getVideoUrl';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import VideoDialog from './VideoDialog';

const mapStateToProps = (state: any) => {
  const precedingVideoExercise = selectors.selectPrecedingVideoExercise(state);
  const urlParams = getUrlParamsFromVideoExercise(precedingVideoExercise);

  return {
    videoUrl: getVideoUrl(urlParams),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onExit: () => dispatch(actions.removeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoDialog);
