import { connect } from 'react-redux';

import { isTabOrBulletConsoleEx } from '../../helpers/ComposedConsoleExercise';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import Reconnecter from './Reconnecter';

const mapStateToProps = (state: any) => {
  const exercise = selectors.selectExercise(state).toJS();
  return {
    shouldRefreshPageOnRestart: isTabOrBulletConsoleEx(exercise.type),
    sessionIsBroken: selectors.isSessionBroken(state),
    backendSession: selectors.selectBackendSession(state),
    exercise: {
      ...exercise,
      ...selectors.selectCurrentSubExercise(state).toJS(),
      pre_exercise_code: exercise.pre_exercise_code,
    },
    exerciseType: exercise.type,
    user: selectors.selectUser(state).toJS(),
    hintAndSolution: selectors.selectHintOrSolution(state),
    systemStatus: selectors.selectSystemStatus(state).toJS(),
    uiTheme: state.getIn(['settings', 'uiTheme']),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  showSolution: (
    { id, isSolution, language, xp }: any,
    restartSession: any,
  ) => {
    dispatch(
      isSolution
        ? actions.showSolution(language, id, xp)
        : actions.showHint(id, xp),
    );
    restartSession();
  },

  showLoginModal: () =>
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.LOGIN })),

  showIssueReporter: () =>
    dispatch(actions.showModal({ modal: selectors.MODAL_TYPE.ISSUE_REPORTER })),

  startSession: (exercise: any) => dispatch(actions.epicStartSession(exercise)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reconnecter);
