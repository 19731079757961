/* eslint-disable no-alert */
import { Dialog } from '@datacamp/waffles/dialog';
import { Undo } from '@datacamp/waffles/icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { isExamExercise } from '../../helpers/examUtilities';
import { formatUrl } from '../../helpers/navigation';
import LinkToDashboard from '../LinkToDashboard';

type Props = {
  course: unknown;
  exerciseType: string;
  resetProgress: (...args: any[]) => any;
};

const OutsideButtons: React.FC<Props> = ({
  course,
  exerciseType,
  resetProgress,
}) => {
  const { t } = useTranslation();
  if (isExamExercise(exerciseType)) {
    return null;
  }
  const onReset = () => {
    const firstConfirmation = window.confirm(
      `Are you REALLY sure you want to reset ALL progress of the course: ${
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{}'.
        course.title
      }? This process can NOT be undone!`,
    );
    if (firstConfirmation) {
      const secondConfirmation = window.confirm(
        'This will irreversibly remove your progress for ALL exercises of this course. Continue?',
      );
      if (secondConfirmation) {
        const chapterRef =
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapters' does not exist on type '{}'.
          course.chapters[0] &&
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapters' does not exist on type '{}'.
          (course.chapters[0].slug || course.chapters[0].id);
        resetProgress({
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
          redirection: formatUrl(course.slug || course.id, chapterRef, 1),
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
          courseId: course.id,
        });
      }
    }
  };

  return (
    <>
      <LinkToDashboard
        redirectUrl={`${config.urls.datacamp}/courses/${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'slug' does not exist on type '{}'.
          course.slug || course.id
        }`}
      />
      <Dialog.Button
        variant="secondary"
        data-cy="outline-reset"
        onClick={onReset}
        iconLeft={<Undo />}
      >
        {t('CourseOutlineOutsideButtons.resetButton')}
      </Dialog.Button>
    </>
  );
};

export default OutsideButtons;
